import { useEffect, useRef, useState } from "react";
import anime from "animejs";
import { Button, colors, Grid, Typography, Breakpoint } from "design-system";

import * as Styles from "./Contact.style";

import copyGrayIcon from "../../assets/common/copy-gray_icon.svg";

import contactContent from "./contact.content.json";
import branding from "../../assets/common/branding-gray.svg";
import axios from "axios";

const letsTalkSection = contactContent.section.letsTalk;
const formSection = contactContent.section.form;
const dataSection = contactContent.section.data;

const VALIDATE_DURATION = 200;
const VALIDATE_EASE = "easeInOutExpo";

const validateInput = (
  input: any,
  errorColor: string,
  defaultColor: string
) => {
  const value = input.value.replace(/^\s+/, "").replace(/\s+$/, "");

  if (value.length < 5) {
    anime({
      targets: input,
      borderColor: errorColor,
      duration: VALIDATE_DURATION,
      easing: VALIDATE_EASE,
    });
    return false;
  } else {
    anime({
      targets: input,
      borderColor: defaultColor,
      duration: VALIDATE_DURATION,
      easing: VALIDATE_EASE,
    });
    return true;
  }
};

const validateEmail = (
  input: any,
  errorColor: string,
  defaultColor: string
) => {
  if (!input.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
    anime({
      targets: input,
      borderColor: errorColor,
      duration: VALIDATE_DURATION,
      easing: VALIDATE_EASE,
    });
    return false;
  } else {
    anime({
      targets: input,
      borderColor: defaultColor,
      duration: VALIDATE_DURATION,
      easing: VALIDATE_EASE,
    });
    return true;
  }
};

const Contact = ({ language, acfId }: any) => {
  // States
  const [content, setContent]: any = useState({});

  // Fetch content
  useEffect(() => {
    axios
      .get(
        `https://cms.maciejtrojanowski.com/wp-json/acf/v3/pages/${acfId}?lang=${language}`
      )
      .then(function (res) {
        setContent(res.data.acf);
        console.log(res.data.acf);
      });
  }, [language]);

  // Checkbox status
  const [checkboxStatus, setCheckboxStatus] = useState(false);

  // Form element
  const nameInputRef: any = useRef<HTMLInputElement>(null);
  const emailInputRef: any = useRef<HTMLInputElement>(null);
  const messageTextAreaRef: any = useRef<HTMLTextAreaElement>(null);
  const checkboxRef: any = useRef<HTMLDivElement>(null);

  const handleCheckboxClick = () => {
    if (checkboxStatus) {
      setCheckboxStatus(false);
    } else {
      setCheckboxStatus(true);
    }
  };

  const handleFormSubmit = () => {
    const fullNameInputStatus = validateInput(
      nameInputRef.current,
      colors.destructive[80],
      colors.secondary[60]
    );
    const emailInputStatus = validateInput(
      emailInputRef.current,
      colors.destructive[80],
      colors.secondary[60]
    );
    const emailStatus = validateEmail(
      emailInputRef.current,
      colors.destructive[80],
      colors.secondary[60]
    );
    const messageTextAreaStatus = validateInput(
      messageTextAreaRef.current,
      colors.destructive[80],
      colors.secondary[60]
    );

    const checkboxEl: any = checkboxRef.current;

    if (!checkboxStatus) {
      anime({
        targets: checkboxEl.children[0],
        borderColor: colors.destructive[80],
        duration: VALIDATE_DURATION,
        easing: VALIDATE_EASE,
      });
      anime({
        targets: checkboxEl.children[1],
        color: colors.destructive[80],
        duration: VALIDATE_DURATION,
        easing: VALIDATE_EASE,
      });
    } else {
      anime({
        targets: checkboxEl.children[0],
        borderColor: colors.secondary[60],
        duration: VALIDATE_DURATION,
        easing: VALIDATE_EASE,
      });
      anime({
        targets: checkboxEl.children[1],
        color: colors.black,
        duration: VALIDATE_DURATION,
        easing: VALIDATE_EASE,
      });
    }

    if (
      fullNameInputStatus &&
      emailInputStatus &&
      emailStatus &&
      messageTextAreaStatus &&
      checkboxStatus
    ) {
      // Send email
    }
  };

  // Copy
  const copyToClipboard = (e: any) => {
    const target = e.currentTarget;
    const copyValue = target.dataset.copy;
    const parent = target.parentNode;

    const fadeEl = document.createElement("div");
    fadeEl.style.color = colors.secondary[20];
    fadeEl.style.position = "absolute";
    fadeEl.style.left = target.offsetLeft + target.offsetLeft / 6 + "px";
    fadeEl.style.top = target.offsetTop + "px";
    fadeEl.innerText = copyValue;

    anime({
      targets: fadeEl,
      translateY: 16,
      opacity: [1, 0],
      duration: 1000,
      easing: "easeOutExpo",
      complete: () => {
        fadeEl.remove();
      },
    });

    parent.appendChild(fadeEl);

    navigator.clipboard.writeText(copyValue);
  };

  if (content.contactHeader)
    return (
      <>
        <Styles.Contact>
          <Grid>
            <Styles.ContactLetsTalk>
              <Styles.ContactLetsTalkHeader>
                <Breakpoint m ms tp>
                  <Typography type="header" variant="xl" font="secondary">
                    {content.contactHeader}
                  </Typography>
                </Breakpoint>

                <Breakpoint tl>
                  <Typography type="header" variant="xxl" font="secondary">
                    {content.contactHeader}
                  </Typography>
                </Breakpoint>
              </Styles.ContactLetsTalkHeader>

              <Styles.ContactLetsTalkGrid>
                <Styles.ContactLetsTalkBox>
                  <Typography type="subtitle" variant="inline" strong>
                    {content.contactContact.header}
                  </Typography>

                  <Styles.ContactLetsTalkBoxData>
                    <Typography type="subtitle">
                      {content.contactContact.phoneNumber}
                    </Typography>
                    <Typography type="subtitle">
                      {content.contactContact.email}
                    </Typography>
                  </Styles.ContactLetsTalkBoxData>

                  <Styles.ContactLetsTalkCommunicators>
                    {content.contactContact.comunicators.map(
                      (communicator: any) => (
                        <Styles.ContactLetsTalkCommunicatorsIcon
                          key={communicator.logo.id}
                          src={communicator.logo.url}
                        />
                      )
                    )}
                  </Styles.ContactLetsTalkCommunicators>

                  <Button
                    width="fit"
                    icon="https://cms.maciejtrojanowski.com/wp-content/uploads/2021/10/timer-24px-black.svg"
                    type="filled"
                    variant="tertiary"
                  >
                    <Typography type="button">
                      {content.contactContact.button.label}
                    </Typography>
                  </Button>
                </Styles.ContactLetsTalkBox>

                <Styles.ContactLetsTalkBox>
                  <Typography type="subtitle" variant="inline" strong>
                    {content.contactMeet.header}
                  </Typography>

                  <Styles.ContactLetsTalkBoxData>
                    <Typography type="subtitle">
                      {content.contactMeet.street}
                    </Typography>

                    <Typography type="subtitle">
                      {content.contactMeet.zipcode}
                    </Typography>
                  </Styles.ContactLetsTalkBoxData>

                  <Styles.ContactLetsTalkMaps
                    target="_blank"
                    href={content.contactMeet.buttonMaps.link}
                  >
                    <Typography type="button">
                      {content.contactMeet.buttonMaps.label}
                    </Typography>
                  </Styles.ContactLetsTalkMaps>

                  <Button
                    width="fit"
                    icon="https://cms.maciejtrojanowski.com/wp-content/uploads/2021/10/calendar-24p-black.svg"
                    type="filled"
                    variant="tertiary"
                  >
                    <Typography type="button">
                      {content.contactMeet.button.label}
                    </Typography>
                  </Button>
                </Styles.ContactLetsTalkBox>
              </Styles.ContactLetsTalkGrid>
            </Styles.ContactLetsTalk>

            <Styles.ContactForm>
              <Styles.ContactFormHeader>
                <Typography type="header" variant="s" strong font="secondary">
                  {content.formHeader}
                </Typography>
              </Styles.ContactFormHeader>

              <Styles.ContactFormInputContainer>
                <Styles.ContactFormInput
                  ref={nameInputRef}
                  placeholder={content.formFullname}
                />
                <Styles.ContactFormInput
                  ref={emailInputRef}
                  placeholder={content.formEmail}
                />
              </Styles.ContactFormInputContainer>

              {/* TODO */}
              <Styles.ContactFormTextArea
                ref={messageTextAreaRef}
                placeholder={content.formTextarea}
              />

              <Styles.ContactFormCheckboxContainer
                ref={checkboxRef}
                onClick={handleCheckboxClick}
              >
                <Styles.ContactFormCheckbox>
                  {checkboxStatus ? <Styles.ContactFormCheckboxSelected /> : ""}
                </Styles.ContactFormCheckbox>
                <Typography type="overline">{content.formAgree}</Typography>
              </Styles.ContactFormCheckboxContainer>

              <Styles.ContactFormButtonSend onClick={handleFormSubmit}>
                <Button width="fit" variant="black">
                  <Typography type="button">{content.formSend}</Typography>
                </Button>
              </Styles.ContactFormButtonSend>
            </Styles.ContactForm>

            <Styles.ContactCompanyData>
              <Styles.ContactCompanyDataHeader>
                <Typography tag="span" type="header" variant="xs" strong={true}>
                  {dataSection.company}
                </Typography>

                <Typography tag="span" type="header" variant="xs" strong={true}>
                  {dataSection.ceo}
                </Typography>
              </Styles.ContactCompanyDataHeader>

              <Styles.ContactCompanyNipRegon>
                <Styles.ContactCompanyNipRegonBox
                  onClick={copyToClipboard}
                  data-copy={dataSection.nip}
                >
                  <Typography type="caption">
                    {`NIP ${dataSection.nip}`}
                  </Typography>
                  <Styles.ContactCompanyNipRegonBoxIcon src={copyGrayIcon} />
                </Styles.ContactCompanyNipRegonBox>

                <Styles.ContactCompanyNipRegonBox
                  onClick={copyToClipboard}
                  data-copy={dataSection.regon}
                >
                  <Typography type="caption">
                    {`REGON ${dataSection.regon}`}
                  </Typography>
                  <Styles.ContactCompanyNipRegonBoxIcon src={copyGrayIcon} />
                </Styles.ContactCompanyNipRegonBox>
              </Styles.ContactCompanyNipRegon>

              <Styles.ContactCompanyBank
                onClick={copyToClipboard}
                data-copy={`${dataSection.bank.name} ${dataSection.bank.account}`}
              >
                <Typography type="caption">
                  {`${dataSection.bank.name} ${dataSection.bank.account}`}
                </Typography>
                <Styles.ContactCompanyNipRegonBoxIcon src={copyGrayIcon} />
              </Styles.ContactCompanyBank>
            </Styles.ContactCompanyData>

            <Styles.ContactBranding>
              <Styles.ContactBrandingName>
                <Typography type="caption">
                  © Trojanowski Radca Prawny
                </Typography>
              </Styles.ContactBrandingName>

              <Styles.ContactBrandingIcon src={branding} />
            </Styles.ContactBranding>
          </Grid>
        </Styles.Contact>
      </>
    );

  return <div>Loading ...</div>;
};

export { Contact };
