var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { grid, devices } from "../../variables";
var Grid = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: grid;\n\n  ", ";\n"], ["\n  width: 100%;\n  height: 100%;\n  display: grid;\n\n  ", ";\n"])), grid.map(function (style) {
    return devices[style.breakpoint] + "{\n        grid-template-columns: " + (style.column ? style.column : "") + ";\n        gap: 0 " + (style.gap ? style.gap : "") + ";\n        padding: 0 " + (style.padding ? style.padding : "") + ";\n      }";
}));
export { Grid };
var templateObject_1;
