import styled from "styled-components";
import { colors, devices } from "design-system";

const ArticleBoxImage = styled.img``;
const ArticleBoxTitle = styled.div``;
const ArticleBoxReadButton = styled.div``;
const ArticleReadMoreIcon = styled.img``;

const ArticleBox = styled.a`
  display: block;
  
  ${ArticleBoxImage} {
    width: 100%;
  }

  ${ArticleBoxTitle} {
    margin: 8px 0 16px 0;
  }

  ${ArticleBoxReadButton} {
    width: fit-content;
    display: flex;
    gap: 24px;
    align-items: center;
    color: ${colors.primary[80]};
  }

  ${devices.tabletPortrait} {
    ${ArticleBoxTitle} {
      margin: 16px 0 24px 0;
    }

    ${ArticleBoxReadButton} {
      gap: 32px;
    }
  }

  ${devices.tabletLandscape} {
    ${ArticleBoxTitle} {
      margin: 24px 0 32px 0;
    
  }
`;

export {
  ArticleBox,
  ArticleBoxImage,
  ArticleBoxTitle,
  ArticleBoxReadButton,
  ArticleReadMoreIcon,
};
