import anime from "animejs";
import { Button, Grid, Typography, Breakpoint } from "design-system";
import { CoopPopup } from "components";
import * as Styles from "./Coop.style";

import coopLineMobile from "assets/home/cooperation-line-mobile.svg";
import coopLineTp from "assets/home/cooperation-line-tablet-portrait.svg";
import coopLineDesktop from "assets/home/cooperation-line-desktop.svg";

const CoopSection = ({ content }: any) => {
  const openPopup = (e: any) => {
    const popup = e.currentTarget.nextSibling;

    popup.style.zIndex = 100;

    anime({
      targets: popup,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      duration: 600,
      easing: "easeInOutExpo",
    });

    anime({
      targets: popup.children[0].children[0],
      top: "0%",
      opacity: 1,
      duration: 800,
      easing: "easeInOutExpo",
    });
  };

  return (
    <Styles.CoopSection id="coop-section">
      <Grid>
        <Breakpoint ms m>
          <Styles.CoopLine src={coopLineMobile} />
        </Breakpoint>

        <Breakpoint tp>
          <Styles.CoopLine src={coopLineTp} />
        </Breakpoint>

        <Breakpoint tl>
          <Styles.CoopLine src={coopLineDesktop} />
        </Breakpoint>

        <Styles.CoopHeader>
          <Breakpoint ms m>
            <Typography type="header" variant="s" font="secondary">
              {content.coopHeader}
            </Typography>
          </Breakpoint>

          <Breakpoint tp tl>
            <Typography type="header" variant="m" font="secondary">
              {content.coopHeader}
            </Typography>
          </Breakpoint>
        </Styles.CoopHeader>

        <Styles.CoopBoxes>
          {content.coopBoxes.map((box: any, index: number) => (
            <Styles.CoopBox key={index}>
              <Styles.CoopBoxHeader>
                <Breakpoint ms m tp>
                  <Typography type="header" variant="m" font="secondary">
                    {box.header}
                  </Typography>
                </Breakpoint>

                <Breakpoint tl>
                  <Typography type="header" variant="xl" font="secondary">
                    {box.header}
                  </Typography>
                </Breakpoint>
              </Styles.CoopBoxHeader>

              <Styles.CoopBoxDescription>
                <Breakpoint ms m tp>
                  <>{box.desc}</>
                </Breakpoint>

                <Breakpoint tl>
                  <Typography type="subtitle">{box.desc}</Typography>
                </Breakpoint>
              </Styles.CoopBoxDescription>

              <Styles.CoopBoxButtons>
                <Button width="fill" icon={box.button1.icon} variant="black">
                  <Typography type="button">{box.button1.label}</Typography>
                </Button>

                <Button width="fill" variant="tertiary">
                  <Typography type="button">{box.button2.label}</Typography>
                </Button>
              </Styles.CoopBoxButtons>

              <Styles.CoopTextButton onClick={openPopup}>
                <Typography type="button">{box.button3.label}</Typography>
              </Styles.CoopTextButton>

              {/*<CoopPopup*/}
              {/*  header={box.textButton.label}*/}
              {/*  content={box.textButton.content}*/}
              {/*/>*/}
            </Styles.CoopBox>
          ))}
        </Styles.CoopBoxes>
      </Grid>
    </Styles.CoopSection>
  );
};

export { CoopSection };
