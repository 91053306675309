import styled from "styled-components";
import { colors, devices } from "design-system";

const ArticleHeroSection = styled.section``;
const ArticleHeroTitle = styled.div``;
const ArticleHeroImage = styled.img``;
const ArticleHeroDescription = styled.div``;

const ArticleContentSection = styled.section``;
const ArticleContent = styled.div``;
const ArticleContentHeader = styled.div``;
const ArticleContentDescription = styled.div``;
const ArticlesQuota = styled.div``;

const ArticleAuthorSection = styled.section``;
const ArticleAuthor = styled.div``;
const ArticleAuthorImage = styled.img``;
const ArticleAuthorTextContainer = styled.div``;

const ArticleRecommendedSection = styled.section``;
const ArticleRecommendedHeader = styled.div``;
const ArticleRecommendedCarousel = styled.div``;
const ArticleRecommendedBox = styled.div``;

const SingleArticle = styled.div`
  ${ArticleHeroSection} {
    margin-top: 4vh;

    ${ArticleHeroTitle} {
      grid-area: 1 / 1 / 2 / 5;
      padding: 0 8px;
    }

    ${ArticleHeroImage} {
      grid-area: 2 / 1 / 3 / 5;
      width: calc(100% + 32px);
      margin: 16px 0 16px -16px;
    }

    ${ArticleHeroDescription} {
      grid-area: 3 / 1 / 4 / 5;
      width: 100%;
      padding: 0 8px;
      font-family: "Playfair Display", serif;
    }
  }

  ${ArticleContentSection} {
    ${ArticleContent} {
      margin-top: 40px;
      grid-column-start: 1;
      grid-column-end: 5;
      padding: 0 8px;

      ${ArticleContentHeader} {
        margin-bottom: 16px;
      }

      ${ArticleContentDescription} {
        p {
          margin-bottom: 16px;
          :last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    ${ArticlesQuota} {
      grid-column-start: 1;
      grid-column-end: 5;
      padding: 10vh 8px 6vh 32px;
      display: flex;
      flex-direction: column;

      .quota {
        margin-top: 20px;
        font-size: 80px;
        line-height: 80px;
        margin-left: auto;
      }
    }
  }

  ${ArticleAuthorSection} {
    margin-top: 32px;
    ${ArticleAuthor} {
      padding: 0 8px;
      margin-left: auto;
      grid-area: 1 / 1 / 2 / 5;
      display: flex;
      text-align: right;
      align-items: center;

      ${ArticleAuthorImage} {
        border-radius: 8px;
        margin: auto 0;
      }

      ${ArticleAuthorTextContainer} {
        margin-right: 12px;
        .author-name {
          color: ${colors.primary[100]};
        }
      }
    }
  }

  ${ArticleRecommendedSection} {
    margin: 16vh 0;

    ${ArticleRecommendedHeader} {
      grid-area: 1 / 1 / 2 / 5;
      padding: 0 8px;
      margin-bottom: 24px;
    }

    ${ArticleRecommendedCarousel} {
      grid-area: 2 / 1 / 3 / 5;
      padding: 0 24px 0 0;
      margin-left: -16px;
      width: 100vw;
      display: flex;
      overflow-y: auto;
      overscroll-behavior-x: contain;
      scroll-snap-type: x mandatory;

      ${ArticleRecommendedBox} {
        scroll-snap-align: center;
        margin-right: 16px;
        min-width: calc(100% - 32px);
        :first-child {
          margin-left: 16px;
        }
      }
    }
  }

  ${devices.tabletPortrait} {
    ${ArticleHeroSection} {
      margin-top: 6vh;

      ${ArticleHeroTitle} {
        grid-column-end: 9;
      }

      ${ArticleHeroImage} {
        grid-column-start: 2;
        grid-column-end: 9;
        margin: 40px 0 48px 0;
      }

      ${ArticleHeroDescription} {
        grid-column-end: 9;
      }
    }

    ${ArticleContentSection} {
      ${ArticleContent} {
        margin-top: 6vh;
        grid-column-end: 9;
      }

      ${ArticlesQuota} {
        grid-column-start: 2;
        grid-column-end: 9;
        margin-right: 0;
        .quota {
          margin-top: 32px;
          font-size: 105px;
        }
      }
    }

    ${ArticleAuthorSection} {
      margin-top: 24px;
      ${ArticleAuthor} {
        grid-column-end: 9;
      }
    }

    ${ArticleRecommendedSection} {
      ${ArticleRecommendedHeader} {
        grid-area: 1 / 1 / 2 / 9;
        padding: 0;
        margin-bottom: 40px;
      }

      ${ArticleRecommendedCarousel} {
        grid-column-end: 9;
        padding: 0;
        margin-left: 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 20px;
        overflow-y: hidden;

        ${ArticleRecommendedBox} {
          margin-right: 0;
          min-width: 100%;
          :first-child {
            margin-left: 0;
          }

          :nth-child(n + 3) {
            display: none;
          }
        }
      }
    }
  }

  ${devices.tabletLandscape} {
    ${ArticleHeroSection} {
      margin-top: 10vh;

      ${ArticleAuthor} {
        margin-top: calc(32px + 10vh);
        margin-left: auto;
        grid-area: 2 / 1 / 3 / 5;
        display: flex;
        text-align: right;
        align-items: start;

        ${ArticleAuthorImage} {
          border-radius: 8px;
        }

        ${ArticleAuthorTextContainer} {
          margin-right: 12px;
          .author-name {
            color: ${colors.primary[100]};
          }
        }
      }

      ${ArticleHeroTitle} {
        grid-column-start: 5;
        grid-column-end: 12;
      }

      ${ArticleHeroImage} {
        grid-column-start: 6;
        grid-column-end: 13;
        margin-top: 32px;
      }

      ${ArticleHeroDescription} {
        grid-column-start: 5;
        grid-column-end: 13;
      }
    }

    ${ArticleContentSection} {
      ${ArticleContent} {
        margin-top: 64px;
        grid-column-start: 5;
        grid-column-end: 13;
      }

      ${ArticlesQuota} {
        grid-column-start: 4;
        grid-column-end: 12;
        padding: 16vh 0 0 0;
      }
    }

    ${ArticleRecommendedSection} {
      ${ArticleRecommendedHeader} {
        grid-column-start: 3;
        grid-column-end: 15;
        margin-bottom: 32px;
      }

      ${ArticleRecommendedCarousel} {
        grid-column-start: 3;
        grid-column-end: 15;
      }
    }
  }
`;

export {
  SingleArticle,
  ArticleHeroSection,
  ArticleHeroTitle,
  ArticleHeroImage,
  ArticleHeroDescription,
  ArticleContentSection,
  ArticleContent,
  ArticleContentHeader,
  ArticleContentDescription,
  ArticlesQuota,
  ArticleAuthorSection,
  ArticleAuthor,
  ArticleAuthorImage,
  ArticleAuthorTextContainer,
  ArticleRecommendedSection,
  ArticleRecommendedHeader,
  ArticleRecommendedCarousel,
  ArticleRecommendedBox,
};
