import styled from "styled-components";
import { GridStyles, colors, devices } from "design-system";

const AboutLine = styled.img``;
const AboutDesktopTopLine = styled.img``;
const AboutDesktopBottomLine = styled.img``;
const AboutHeader = styled.div``;
const AboutSubtitle = styled.div``;
const AboutDescription = styled.div``;
const AboutLineDummyPlug = styled.div``;

const AboutSection = styled.section`
  padding: 16vh 0 20vh 0;

  .about-image {
    grid-area: 1 / 2 / 2 / 5;
  }

  ${AboutLine} {
    grid-area: 1 / 1 / 2 / 5;
    height: 100%;
    width: calc(100% + 16px);
    object-fit: contain;
  }

  ${AboutDesktopTopLine},
  ${AboutDesktopBottomLine} {
    object-fit: contain;
    display: none;
  }

  ${AboutLineDummyPlug} {
    display: none;
  }

  ${AboutHeader} {
    grid-area: 2 / 1 / 3 / 5;
    padding: 32px 8px 0 24px;
  }

  ${AboutSubtitle} {
    grid-area: 3 / 1 / 4 / 5;
    padding: 8px 16px 24px 24px;
    color: ${colors.primary[100]};
  }

  ${AboutDescription} {
    grid-area: 4 / 1 / 5 / 5;
    padding: 0 16px 0 24px;
  }

  ${devices.tabletPortrait} {
    padding: 16vh 0;

    .about-image {
      grid-column-start: 5;
      grid-column-end: 9;
      width: 100%;
      margin-left: 32px;
    }

    ${AboutLine} {
      grid-area: 1 / 4 / 2 / 9;
      padding: 0;
      margin: auto 0 auto 0;
      width: calc(100% + 32px);
    }

    ${AboutHeader} {
      grid-column-start: 2;
      grid-column-end: 7;
      margin-top: -6vh;
      padding: 0 16px 0 0;
    }

    ${AboutSubtitle} {
      grid-column-start: 2;
      grid-column-end: 7;
      padding: 0 16px 32px 0;
    }

    ${AboutDescription} {
      grid-column-start: 2;
      grid-column-end: 7;
      padding: 0 16px 0 0;
    }
  }

  ${devices.tabletLandscape} {
    padding: 26vh 0 36vh 0;

    ${GridStyles.GridContainer} {
      grid-template-rows: auto auto auto auto 1fr;
    }

    .about-image {
      grid-column-start: 4;
      grid-row-end: 5;
      margin-left: 0;
      z-index: 11;
    }

    ${AboutLine} {
      display: none;
    }

    ${AboutLineDummyPlug} {
      display: block;
      background-color: ${colors.white};
      grid-area: 1 / 9 / 5 / 10;
      margin-left: -20px;
      width: 100%;
      height: 100%;
      transform: translateY(50%);
      z-index: 12;
      position: relative;
    }

    ${AboutDesktopTopLine} {
      grid-area: 1 / 2 / 5 / 17;
      display: block;
      margin: 6vh 0 10vh;
      width: calc(100% + 32px);
      z-index: 10;
    }

    ${AboutDesktopBottomLine} {
      grid-area: 1 / 2 / 5 / 17;
      display: block;
      z-index: 11;
      margin: 6vh 0 10vh;
      width: calc(100% + 32px);
    }

    ${AboutHeader} {
      grid-area: 1 / 10 / 2 / 15;
      margin-top: 26vh;
      padding: 0;
    }

    ${AboutSubtitle} {
      grid-area: 2 / 10 / 3 / 15;
      padding: 8px 0 0 0;
    }

    ${AboutDescription} {
      grid-area: 3 / 10 / 4 / 15;
      padding: 32px 0 0 0;
    }
  }
`;

export {
  AboutSection,
  AboutLine,
  AboutDesktopTopLine,
  AboutDesktopBottomLine,
  AboutLineDummyPlug,
  AboutHeader,
  AboutSubtitle,
  AboutDescription,
};
