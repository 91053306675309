import { useEffect, useState } from "react";
import axios from "axios";

import {
  HeroSection,
  HelpSection,
  CoopSection,
  AboutSection,
  OpinionSection,
  ClientSection,
  FAQSection,
} from "./section";
import { CTASection, Footer } from "components";

import defaultContent from "./default.content.json";

const Home = ({ language, acfId }: any) => {
  // States
  const [content, setContent]: any = useState({});

  // Fetch content
  useEffect(() => {
    axios
      .get(
        `https://cms.maciejtrojanowski.com/wp-json/acf/v3/pages/${acfId}?lang=${language}`
      )
      .then(function (res) {
        setContent(res.data.acf);
      });
  }, [language]);

  if (content.heroHeader)
    return (
      <>
        <HeroSection content={content} />
        <HelpSection content={content} />
        <CoopSection content={content} />
        <AboutSection content={content} />
        <OpinionSection content={content} />
        <ClientSection content={content} />
        <FAQSection content={content} />
        <CTASection />
        <Footer />
      </>
    );

  return <div>Loading ...</div>;
};

export { Home };
