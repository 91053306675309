var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { commonButtonsStyle, devices } from "../../variables";
var ButtonIcon = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var Button = styled.button(function (props) { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: ", ";\n    outline: none;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    ", "\n\n    color: ", ";\n    background-color: ", ";\n    border-color: ", ";\n\n    ", "\n\n    ", " {\n      order: ", ";\n    }\n  "], ["\n    width: ", ";\n    outline: none;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    ", "\n\n    color: ", ";\n    background-color: ", ";\n    border-color: ", ";\n\n    ", "\n\n    ", " {\n      order: ", ";\n    }\n  "])), props.width === "fill" ? "100%" : "fit-content", props.icon ? "gap: 0 8px; " : "", props.styles.normal.color, props.styles.normal.bg, props.styles.normal.borderColor, commonButtonsStyle.map(function (style) {
    return devices[style.breakpoint] + "{\n        padding: " + (style.padding ? style.padding : "") + ";\n        border-style: " + (style.borderType ? style.borderType : "") + ";\n        border-width: " + (style.borderWidth ? style.borderWidth : "") + ";\n        border-radius: " + (style.borderRadius ? style.borderRadius : "") + ";\n      }";
}), ButtonIcon, props.iconPosition); });
export { Button, ButtonIcon };
var templateObject_1, templateObject_2;
