import { Grid } from "design-system";
import * as Styles from "./Articles.style";
import { ArticleBox } from "components";

const ArticlesSection = ({ paginationArticles }: any) => {
  return (
    <Styles.ArticlesSection>
      <Grid>
        <Styles.ArticlesBoxes>
          {paginationArticles.map(
            (article: {
              id: number;
              thumbnail: string;
              title: string;
              slug: string;
            }) => (
              <ArticleBox
                key={article.id}
                thumbnail={article.thumbnail}
                title={article.title}
                slug={`${process.env.PUBLIC_URL}/artykuly/${article.slug}`}
              />
            )
          )}
        </Styles.ArticlesBoxes>
      </Grid>
    </Styles.ArticlesSection>
  );
};

export { ArticlesSection };
