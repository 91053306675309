import styled from "styled-components";
import { ButtonStyle, colors, devices } from "design-system";

const Help = styled.div``;
const HelpHeader = styled.div``;

const HelpDropdown = styled.div``;
const HelpDropdownLabelWrapper = styled.div``;
const HelpDropdownLabel = styled.div``;
const HelpDropdownExpand = styled.img``;
const HelpDropdownOptions = styled.div``;
const HelpDropdownOption = styled.div``;
const Label = styled.div``;
const Icon = styled.img`
  object-fit: contain;
`;

const HelpContext = styled.div``;
const HelpContextBox = styled.div``;
const HelpContextBoxIcon = styled.img``;
const HelpLanguages = styled.div``;
const HelpLanguageHeader = styled.div``;
const HelpLanguageList = styled.div``;
const HelpLanguageBox = styled.div``;
const HelpLanguageBoxIcon = styled.img``;
const HelpButtons = styled.div``;

const HelpSection = styled.section`
  padding: 16vh 0;
  ${Help} {
    grid-area: 1 / 1 / 2 / 5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
    background-color: ${colors.white};
    padding: 24px 32px 32px 32px;

    ${HelpHeader} {
      text-align: center;
    }

    ${HelpDropdown} {
      margin: 16px 8px 32px 8px;
      position: relative;
      cursor: pointer;

      ${HelpDropdownLabelWrapper} {
        padding: 16px 12px;
        border: 1px solid ${colors.secondary[60]};
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        background-color: ${colors.white};

        ${HelpDropdownLabel} {
          display: flex;

          * {
            margin: auto 0;
          }

          ${Icon} {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }

        ${HelpDropdownExpand} {
          width: 24px;
          height: 24px;
          object-fit: contain;
          margin: auto 0;
          transform: rotateZ(0deg);
          transition: all 0.2s ease-out;

          &.open {
            transform: rotateZ(-180deg);
          }
        }
      }

      ${HelpDropdownOptions} {
        position: absolute;
        width: 100%;
        padding: 16px 12px;
        background-color: ${colors.white};
        border: 1px solid ${colors.secondary[60]};
        border-radius: 4px;
        top: calc(100% + 4px);
        opacity: 0;

        ${HelpDropdownOption} {
          display: flex;
          margin-bottom: 16px;
          :last-child {
            margin-bottom: 0;
          }

          * {
            margin: auto 0;
          }

          ${Icon} {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
      }
    }

    ${HelpContext} {
      display: grid;
      gap: 16px 0;
      ${HelpContextBox} {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 0 8px;
      }
    }

    ${HelpLanguages} {
      ${HelpLanguageHeader} {
        text-align: center;
        margin-top: 32px;
        margin-bottom: 12px;
      }
      ${HelpLanguageList} {
        display: flex;
        justify-content: center;
        gap: 20px;
        ${HelpLanguageBox} {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 0 4px;
        }
      }
    }

    ${HelpButtons} {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      gap: 16px 0;
      margin-top: 40px;
      ${ButtonStyle.ButtonLink} {
        * {
          margin: 0 auto;
        }
      }
    }
  }

  ${devices.tabletPortrait} {
    ${Help} {
      grid-column-end: 9;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 0 16px;
      padding: 6vh 40px;
      ${HelpHeader} {
        grid-area: 1 / 1 / 2 / 5;
        text-align: left;
        padding-left: 8px;
        margin: auto 0;
      }

      ${HelpDropdown} {
        grid-area: 1 / 5 / 2 / 9;
        margin: 0;
      }

      ${HelpContext} {
        grid-area: 2 / 1 / 3 / 9;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 24px;
        margin-top: 48px;
        padding: 0 8px;
      }

      ${HelpLanguages} {
        grid-area: 3 / 1 / 4 / 9;
        padding: 0 8px;
        ${HelpLanguageHeader} {
          margin-top: 48px;
        }
        ${HelpLanguageList} {
          gap: 32px;
          ${HelpLanguageBox} {
            gap: 0 8px;
          }
        }
      }

      ${HelpButtons} {
        grid-area: 4 / 1 / 5 / 9;
        display: flex;
        justify-content: center;
        gap: 24px;
        margin-top: 48px;
        ${ButtonStyle.Button} {
          margin: 0;
        }
      }
    }
  }

  ${devices.tabletLandscape} {
    padding: 20vh 0 16vh 0;
    ${Help} {
      grid-column-start: 3;
      grid-column-end: 15;
      grid-template-columns: repeat(12, 1fr);
      gap: 0 20px;
      padding: 10vh 0;
      ${HelpHeader} {
        grid-column-start: 2;
        grid-column-end: 7;
        padding-left: 0;
      }

      ${HelpDropdown} {
        grid-column-start: 7;
        grid-column-end: 12;
        padding-right: 0;
      }

      ${HelpContext} {
        grid-column-start: 2;
        grid-column-end: 12;
        padding: 0;
      }

      ${HelpLanguages} {
        grid-column-start: 2;
        grid-column-end: 12;
        padding: 0;
      }

      ${HelpButtons} {
        grid-column-start: 2;
        grid-column-end: 12;
      }
    }
  }
`;

export {
  HelpSection,
  Help,
  HelpHeader,
  HelpDropdown,
  HelpDropdownLabelWrapper,
  HelpDropdownLabel,
  HelpDropdownExpand,
  HelpDropdownOptions,
  HelpDropdownOption,
  Label,
  Icon,
  HelpContext,
  HelpContextBox,
  HelpContextBoxIcon,
  HelpLanguages,
  HelpLanguageHeader,
  HelpLanguageList,
  HelpLanguageBox,
  HelpLanguageBoxIcon,
  HelpButtons,
};
