import styled from "styled-components";
import { colors, devices } from "design-system";

const OpinionCarousel = styled.div``;
const OpinionBox = styled.div``;
const OpinionBoxContent = styled.div``;
const OpinionBoxHeader = styled.div``;

const OpinionArrows = styled.div``;
const OpinionArrow = styled.img``;

const OpinionSection = styled.section`
  ${OpinionCarousel} {
    margin-left: -16px;
    width: 100vw;
    grid-area: 1 / 1 / 2 / 5;
    display: flex;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    ${OpinionBox} {
      scroll-snap-align: center;
      margin-right: 16px;
      min-width: calc(100% - 32px);
      border: 1px solid ${colors.primary[100]};
      border-radius: 16px;
      padding: 32px 24px;
      :first-child {
        margin-left: 16px;
      }
      ${OpinionBoxContent} {
        margin-bottom: 32px;
      }
    }
  }

  ${OpinionArrows} {
    grid-area: 2 / 2 / 3 / 5;
    margin: 36px 0 0 auto;
    padding: 0 4px;
    display: flex;
    gap: 16px;
    ${OpinionArrow} {
      cursor: pointer;
    }
  }

  ${devices.tabletPortrait} {
    ${OpinionCarousel} {
      grid-column-end: 9;
      margin-left: -32px;
      ${OpinionBox} {
        margin-right: 32px;
        min-width: calc(100% - 64px);
        border-radius: 40px;
        padding: 64px calc((100% - (7 * 16px)) / 8) 48px
          calc((100% - (7 * 16px)) / 8);

        :first-child {
          margin-left: 32px;
        }

        ${OpinionBoxContent} {
          margin-bottom: 40px;
        }

        ${OpinionBoxHeader} {
          margin-left: auto;
          width: fit-content;
        }
      }
    }

    ${OpinionArrows} {
      grid-column-start: 3;
      grid-column-end: 7;
      margin: 16px 0 0 0;
      padding: 0 52px;
      justify-content: space-between;
    }
  }

  ${devices.tabletLandscape} {
    ${OpinionCarousel} {
      padding: 0 4px;
      grid-column-start: 4;
      grid-column-end: 14;
      z-index: 2;
      margin-left: 0;
      width: 100%;
      ${OpinionBox} {
        margin-right: 24px;
        min-width: 100%;
        border-radius: 40px;
        padding: 80px 80px 64px 80px;
        :first-child {
          margin-left: 0;
        }
        ${OpinionBoxContent} {
          margin-bottom: 48px;
        }
      }
    }

    ${OpinionArrows} {
      grid-area: 1 / 3 / 2 / 15;
      margin: 0;
      padding: 0;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      ${OpinionArrow} {
        width: 64px;
        height: 64px;
      }
    }
  }
`;

export {
  OpinionSection,
  OpinionCarousel,
  OpinionBox,
  OpinionBoxContent,
  OpinionBoxHeader,
  OpinionArrows,
  OpinionArrow,
};
