import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Breakpoint, Grid, Typography } from "design-system";

import { ArticleBox, CTASection, Footer } from "../../components";

import * as Styles from "./SingleArticle.style";

import content from "../articles/articles.content.json";

const SingleArticle = (props: any) => {
  // Params
  const { slug } = useParams<{ slug: string }>();

  // States
  const [article, setArticle] = useState(content.section.list.articles[0]);
  const [randomArticles, setRandomArticles] = useState(
    content.section.list.articles
  );

  useEffect(() => {
    content.section.list.articles.map((article) => {
      if (article.slug.replaceAll("/", "") === slug) {
        setArticle(article);
      }
    });

    const random = content.section.list.articles
      .sort(() => Math.random() - Math.random())
      .slice(0, 5);

    setRandomArticles(random);
  }, []);

  return (
    <>
      <Styles.SingleArticle>
        <Styles.ArticleHeroSection>
          <Grid>
            <Breakpoint tl>
              <Styles.ArticleAuthor>
                <Styles.ArticleAuthorTextContainer>
                  <Typography className="author-name" type="caption">
                    Autor tekstu
                  </Typography>
                  <Typography type="header" variant="s">
                    {article.author.fullName}
                  </Typography>
                </Styles.ArticleAuthorTextContainer>
                <Styles.ArticleAuthorImage src={article.author.profile} />
              </Styles.ArticleAuthor>
            </Breakpoint>

            <Styles.ArticleHeroTitle>
              <Breakpoint ms m>
                <Typography type="header" variant="s" font="secondary">
                  {article.title}
                </Typography>
              </Breakpoint>
              <Breakpoint tp tl>
                <Typography type="header" variant="l" font="secondary">
                  Donec id bibendum leo. Quisque euismod varius metus, a tempus
                  mi euismod eu
                </Typography>
              </Breakpoint>
            </Styles.ArticleHeroTitle>

            <Styles.ArticleHeroImage src={article.thumbnail} />

            <Styles.ArticleHeroDescription>
              <Breakpoint ms m>
                <div>
                  Nam at massa a odio auctor consequat. Donec id bibendum leo.
                  Quisque euismod varius metus, a tempus mi euismod eu.
                  Pellentesque augue sem, dictum a quam quis, vestibulum sodales
                  urna.
                </div>
              </Breakpoint>

              <Breakpoint tp tl>
                <Typography type="header" variant="s" font="secondary">
                  Nam at massa a odio auctor consequat. Donec id bibendum leo.
                  Quisque euismod varius metus, a tempus mi euismod eu.
                  Pellentesque augue sem, dictum a quam quis, vestibulum sodales
                  urna.
                </Typography>
              </Breakpoint>
            </Styles.ArticleHeroDescription>
          </Grid>
        </Styles.ArticleHeroSection>

        <Styles.ArticleContentSection>
          <Grid>
            <Styles.ArticleContent>
              <Styles.ArticleContentHeader>
                <Breakpoint ms m tp>
                  <Typography type="subtitle" variant="inline" strong>
                    Lorem ipsum dolor sit amet
                  </Typography>
                </Breakpoint>

                <Breakpoint tl>
                  <Typography strong type="header" variant="xs">
                    Lorem ipsum dolor sit amet
                  </Typography>
                </Breakpoint>
              </Styles.ArticleContentHeader>
              <Styles.ArticleContentDescription>
                <div>
                  <p>
                    Integer neque felis, iaculis eu dui ut, finibus hendrerit
                    turpis. Ut sed commodo dui. Quisque molestie massa nec dui
                    elementum, ac bibendum felis finibus. Aliquam dolor purus,
                    imperdiet sodales libero in, dictum condimentum nibh.
                    Pellentesque tempus neque blandit, mattis justo et, ornare
                    leo. Suspendisse nunc dolor, pharetra ut fermentum et,
                    ullamcorper eu massa. Vivamus suscipit venenatis felis ut
                    pulvinar.
                  </p>
                  <p>
                    Quisque placerat, orci quis gravida sodales, diam ligula
                    blandit quam, ut semper massa nulla id libero. Suspendisse
                    potenti. Nam nec mi vehicula, pharetra arcu quis, dictum
                    massa. Pellentesque ac neque efficitur, lobortis metus in,
                    sagittis sapien. Maecenas elit urna, convallis vel porttitor
                    ac, commodo ac urna. Cras nec nisi at nisl aliquet rhoncus.
                    Suspendisse potenti. Suspendisse quis tempus felis, ut
                    hendrerit ipsum.
                  </p>
                </div>
              </Styles.ArticleContentDescription>
            </Styles.ArticleContent>

            <Styles.ArticleContent>
              <Styles.ArticleContentHeader>
                <Breakpoint ms m tp>
                  <Typography strong type="subtitle" variant="inline">
                    Lorem ipsum dolor sit amet
                  </Typography>
                </Breakpoint>

                <Breakpoint tl>
                  <Typography strong type="header" variant="xs">
                    Lorem ipsum dolor sit amet
                  </Typography>
                </Breakpoint>
              </Styles.ArticleContentHeader>
              <Styles.ArticleContentDescription>
                <div>
                  <p>
                    Integer neque felis, iaculis eu dui ut, finibus hendrerit
                    turpis. Ut sed commodo dui. Quisque molestie massa nec dui
                    elementum, ac bibendum felis finibus. Aliquam dolor purus,
                    imperdiet sodales libero in, dictum condimentum nibh.
                    Pellentesque tempus neque blandit, mattis justo et, ornare
                    leo. Suspendisse nunc dolor, pharetra ut fermentum et,
                    ullamcorper eu massa. Vivamus suscipit venenatis felis ut
                    pulvinar.
                  </p>
                  <p>
                    Quisque placerat, orci quis gravida sodales, diam ligula
                    blandit quam, ut semper massa nulla id libero. Suspendisse
                    potenti. Nam nec mi vehicula, pharetra arcu quis, dictum
                    massa. Pellentesque ac neque efficitur, lobortis metus in,
                    sagittis sapien. Maecenas elit urna, convallis vel porttitor
                    ac, commodo ac urna. Cras nec nisi at nisl aliquet rhoncus.
                    Suspendisse potenti. Suspendisse quis tempus felis, ut
                    hendrerit ipsum.
                  </p>
                </div>
              </Styles.ArticleContentDescription>
            </Styles.ArticleContent>

            <Styles.ArticlesQuota>
              <Typography type="header" variant="xs" font="secondary">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque ut molestie purus. Nullam hendrerit enim et semper
                tincidunt. Ut quis tristique purus. Pellentesque ut molestie
                purus. Nullam hendrerit enim et semper tincidunt. Ut quis
                tristique purus.
              </Typography>

              <Typography
                className="quota"
                type="header"
                variant="xs"
                font="secondary"
              >
                ”
              </Typography>
            </Styles.ArticlesQuota>

            <Styles.ArticleContent>
              <Styles.ArticleContentHeader>
                <Breakpoint ms m tp>
                  <Typography strong type="subtitle" variant="inline">
                    Lorem ipsum dolor sit amet
                  </Typography>
                </Breakpoint>

                <Breakpoint tl>
                  <Typography strong type="header" variant="xs">
                    Lorem ipsum dolor sit amet
                  </Typography>
                </Breakpoint>
              </Styles.ArticleContentHeader>
              <Styles.ArticleContentDescription>
                <div>
                  <p>
                    Integer neque felis, iaculis eu dui ut, finibus hendrerit
                    turpis. Ut sed commodo dui. Quisque molestie massa nec dui
                    elementum, ac bibendum felis finibus. Aliquam dolor purus,
                    imperdiet sodales libero in, dictum condimentum nibh.
                    Pellentesque tempus neque blandit, mattis justo et, ornare
                    leo. Suspendisse nunc dolor, pharetra ut fermentum et,
                    ullamcorper eu massa. Vivamus suscipit venenatis felis ut
                    pulvinar.
                  </p>
                  <p>
                    Quisque placerat, orci quis gravida sodales, diam ligula
                    blandit quam, ut semper massa nulla id libero. Suspendisse
                    potenti. Nam nec mi vehicula, pharetra arcu quis, dictum
                    massa. Pellentesque ac neque efficitur, lobortis metus in,
                    sagittis sapien. Maecenas elit urna, convallis vel porttitor
                    ac, commodo ac urna. Cras nec nisi at nisl aliquet rhoncus.
                    Suspendisse potenti. Suspendisse quis tempus felis, ut
                    hendrerit ipsum.
                  </p>
                </div>
              </Styles.ArticleContentDescription>
            </Styles.ArticleContent>
          </Grid>
        </Styles.ArticleContentSection>

        <Breakpoint ms m tp>
          <Styles.ArticleAuthorSection>
            <Grid>
              <Styles.ArticleAuthor>
                <Styles.ArticleAuthorTextContainer>
                  <Typography className="author-name" type="caption">
                    Autor tekstu
                  </Typography>
                  <Typography type="header" variant="s">
                    {article.author.fullName}
                  </Typography>
                </Styles.ArticleAuthorTextContainer>
                <Styles.ArticleAuthorImage src={article.author.profile} />
              </Styles.ArticleAuthor>
            </Grid>
          </Styles.ArticleAuthorSection>
        </Breakpoint>

        <Styles.ArticleRecommendedSection>
          <Grid>
            <Styles.ArticleRecommendedHeader>
              <Typography type="header" variant="l" font="secondary">
                Polecane artykuły
              </Typography>
            </Styles.ArticleRecommendedHeader>
            <Styles.ArticleRecommendedCarousel>
              {randomArticles.map((article) => (
                <Styles.ArticleRecommendedBox>
                  <ArticleBox
                    thumbnail={article.thumbnail}
                    title={article.title}
                    slug={`${process.env.PUBLIC_URL}/artykuly/${article.slug}`}
                  />
                </Styles.ArticleRecommendedBox>
              ))}
            </Styles.ArticleRecommendedCarousel>
          </Grid>
        </Styles.ArticleRecommendedSection>

        <CTASection />
      </Styles.SingleArticle>
      <Footer />
    </>
  );
};

export { SingleArticle };
