import styled from "styled-components";
import { devices } from "design-system";

const ClientHeader = styled.div``;
const ClientBoxes = styled.div``;
const ClientBox = styled.img``;

const ClientSection = styled.section`
  padding: 16vh 0;
  ${ClientHeader} {
    text-align: center;
    grid-area: 1 / 1 / 2 / 5;
  }

  ${ClientBoxes} {
    margin: 32px auto 0 auto;
    grid-area: 2 / 1 / 3 / 5;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    ${ClientBox} {
      height: 32px;
    }
  }

  ${devices.tabletPortrait} {
    ${ClientHeader} {
      grid-column-end: 9;
    }

    ${ClientBoxes} {
      margin-top: 48px;
      grid-column-end: 9;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px 48px;

      ${ClientBox} {
        height: 48px;
        width: 100%;
      }
    }
  }

  ${devices.tabletLandscape} {
    ${ClientHeader} {
      grid-column-end: 17;
    }

    ${ClientBoxes} {
      width: 100%;
      grid-column-start: 3;
      grid-column-end: 15;
      margin-top: 64px;
      grid-template-columns: repeat(5, 1fr);
      gap: 68px 40px;
    }
  }
`;

export { ClientSection, ClientHeader, ClientBoxes, ClientBox };
