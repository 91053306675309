import styled from "styled-components";
import { colors, devices } from "design-system";

const FAQHeader = styled.div``;
const FAQBoxes = styled.div``;
const FAQBox = styled.div``;
const FAQBoxQuestionContainer = styled.div``;
const FAQBoxQuestionContainerLine = styled.div``;
const FAQBoxQuestionContainerHeader = styled.div``;
const FAQBoxQuestionContainerExpand = styled.img``;
const FAQBoxAnswer = styled.div``;

const FAQSection = styled.section`
  padding-bottom: 16vh;
  ${FAQHeader} {
    grid-area: 1 / 1 / 2 / 5;
    padding: 0 40px 0 8px;
  }

  ${FAQBoxes} {
    margin-top: 32px;
    grid-area: 2 / 1 / 3 / 5;
    ${FAQBox} {
      margin-bottom: 32px;
      padding-left: 8px;

      :first-child {
        ${FAQBoxAnswer} {
          height: 100%;
          opacity: 1;
        }

        ${FAQBoxQuestionContainer} {
          ${FAQBoxQuestionContainerExpand} {
            transform: rotateX(180deg);
          }
        }
      }

      :last-child {
        margin-bottom: 0;
      }

      ${FAQBoxQuestionContainer} {
        cursor: pointer;
        position: relative;
        display: grid;
        grid-template-columns: 1fr auto;
        ${FAQBoxQuestionContainerLine} {
          width: 72px;
          height: 1px;
          background-color: ${colors.primary[100]};
          position: absolute;
        }

        ${FAQBoxQuestionContainerHeader} {
          margin-top: 8px;
          padding-right: 16px;
        }
      }

      ${FAQBoxAnswer} {
        margin-top: 16px;
        color: ${colors.primary[80]};
        height: 0;
        opacity: 0;
      }
    }
  }

  ${devices.tabletPortrait} {
    ${FAQHeader} {
      grid-column-end: 6;
      padding: 0 0 0 40px;
    }

    ${FAQBoxes} {
      margin-top: 64px;
      grid-column-end: 9;
      padding: 0 40px;
      ${FAQBox} {
        padding-left: 0;

        ${FAQBoxQuestionContainer} {
          ${FAQBoxQuestionContainerHeader} {
            margin-top: 16px;
            padding-right: 32px;
          }
        }

        ${FAQBoxAnswer} {
          margin-top: 24px;
          padding-right: 56px;
        }
      }
    }
  }

  ${devices.tabletLandscape} {
    padding-bottom: 26vh;
    ${FAQHeader} {
      grid-column-start: 4;
      grid-column-end: 14;
      padding: 0;
    }

    ${FAQBoxes} {
      grid-column-start: 4;
      grid-column-end: 14;
      padding: 0;
      ${FAQBox} {
        margin-bottom: 48px;

        ${FAQBoxQuestionContainer} {
          ${FAQBoxQuestionContainerHeader} {
            padding-right: calc(100vw / 16 + 20px);
          }
          ${FAQBoxQuestionContainerExpand} {
            margin: auto 0;
          }
        }

        ${FAQBoxAnswer} {
          padding-right: calc(100vw / 16 + 20px + 24px);
        }
      }
    }
  }
`;

export {
  FAQSection,
  FAQHeader,
  FAQBoxes,
  FAQBox,
  FAQBoxQuestionContainer,
  FAQBoxQuestionContainerLine,
  FAQBoxQuestionContainerHeader,
  FAQBoxQuestionContainerExpand,
  FAQBoxAnswer,
};
