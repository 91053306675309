import React, { useEffect, useState } from "react";
import { getBreakpoint } from "./getBreakpoint";
var Breakpoint = function (props) {
    var _a = useState(), breakpoints = _a[0], setBreakpoint = _a[1];
    var _b = useState(false), componentStatus = _b[0], setComponentStatus = _b[1];
    var currentBreakpoint = getBreakpoint();
    useEffect(function () {
        setComponentStatus(false);
        var propsToArray = Object.keys(props).map(function (key) { return key; });
        propsToArray.pop();
        setBreakpoint(propsToArray);
        breakpoints === null || breakpoints === void 0 ? void 0 : breakpoints.map(function (breakpoint) {
            if (currentBreakpoint === breakpoint) {
                setComponentStatus(true);
            }
        });
    }, [currentBreakpoint]);
    if (componentStatus)
        return props.children;
    return React.createElement(React.Fragment, null);
};
export { Breakpoint };
