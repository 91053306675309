import measures from "./measures.json";
var gap = measures.gap;
var padding = measures.padding;
var grid = [
    {
        breakpoint: "mobileSmall",
        column: "repeat(4, 1fr)",
        gap: gap.mobile + "px",
        padding: padding.mobile + "px",
    },
    {
        breakpoint: "tabletPortrait",
        column: "repeat(8, 1fr)",
        gap: gap.tabletPortrait + "px",
        padding: padding.tabletPortrait + "px",
    },
    {
        breakpoint: "tabletLandscape",
        column: "repeat(16, 1fr)",
        gap: gap.tabletLandscape + "px",
        padding: padding.tabletLandscape + "px",
    },
];
export { grid };
