import styled from "styled-components";
import { colors, devices } from "design-system";

const ContactLetsTalk = styled.div``;
const ContactLetsTalkHeader = styled.div``;
const ContactLetsTalkGrid = styled.div``;
const ContactLetsTalkBox = styled.div``;
const ContactLetsTalkBoxData = styled.div``;
const ContactLetsTalkCommunicators = styled.div``;
const ContactLetsTalkCommunicatorsIcon = styled.img``;
const ContactLetsTalkMaps = styled.a``;

const ContactForm = styled.div``;
const ContactFormHeader = styled.div``;
const ContactFormInputContainer = styled.div``;
const ContactFormInput = styled.input``;
const ContactFormTextArea = styled.textarea``;
const ContactFormCheckboxContainer = styled.div``;
const ContactFormCheckbox = styled.div``;
const ContactFormCheckboxSelected = styled.div``;
const ContactFormButtonSend = styled.div``;

const ContactCompanyData = styled.div``;
const ContactCompanyDataHeader = styled.div``;
const ContactCompanyNipRegon = styled.div``;
const ContactCompanyNipRegonBox = styled.div``;
const ContactCompanyNipRegonBoxIcon = styled.img``;
const ContactCompanyBank = styled.div``;

const ContactBranding = styled.div``;
const ContactBrandingName = styled.div``;
const ContactBrandingIcon = styled.img``;

const Contact = styled.div`
  ${ContactLetsTalk} {
    padding: 0 8px;
    margin: 4vh 0 6vh 0;
    grid-area: 1 / 1 / 2 / 5;

    ${ContactLetsTalkGrid} {
      margin-top: 40px;
      display: grid;
      gap: 40px;

      ${ContactLetsTalkBox} {
        padding: 0 8px;

        ${ContactLetsTalkBoxData} {
          margin-top: 8px;
          display: grid;
          gap: 4px;
          * {
            cursor: pointer;
          }
        }

        ${ContactLetsTalkCommunicators} {
          display: flex;
          gap: 16px;
          margin: 24px 0;

          ${ContactLetsTalkCommunicatorsIcon} {
            cursor: pointer;
            width: 24px;
          }
        }

        ${ContactLetsTalkMaps} {
          display: block;
          margin: 24px 0;
          padding-bottom: 4px;
          color: ${colors.primary[60]};
          border-bottom: 1px solid;
          width: fit-content;
        }
      }
    }
  }

  ${ContactForm} {
    grid-area: 2 / 1 / 3 / 5;
    height: fit-content;
    margin: 6vh 0 10vh 0;
    border: 1px solid ${colors.secondary[100]};
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;

    ${ContactFormHeader} {
      text-align: center;
      margin-bottom: 32px;
    }

    ${ContactFormInputContainer} {
      display: grid;
      gap: 24px;

      ${ContactFormInput} {
        font-family: "Montserrat", sans-serif;
        width: 100%;
        outline: none;
        padding: 16px 24px;
        border-radius: 4px;
        border: 1px solid ${colors.secondary[60]};

        ::placeholder {
          color: ${colors.primary[80]};
        }
      }
    }

    ${ContactFormTextArea} {
      font-family: "Montserrat", sans-serif;
      resize: none;
      outline: none;
      width: 100%;
      height: 112px;
      margin-top: 16px;
      padding: 16px 24px;
      border-radius: 4px;
      border: 1px solid ${colors.secondary[60]};

      ::placeholder {
        color: ${colors.primary[80]};
      }
    }

    ${ContactFormCheckboxContainer} {
      cursor: pointer;
      margin-top: 16px;
      display: grid;
      grid-template-columns: 24px 1fr;
      gap: 8px;

      ${ContactFormCheckbox} {
        width: 24px;
        height: 24px;
        border: 1px solid ${colors.secondary[60]};
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        ${ContactFormCheckboxSelected} {
          border-radius: 2px;
          width: 16px;
          height: 16px;
          background-color: ${colors.black};
        }
      }
    }

    ${ContactFormButtonSend} {
      margin: 16px auto 0 auto;
    }
  }

  ${ContactCompanyData} {
    margin: 6vh 0 10vh 0;
    grid-area: 3 / 1 / 4 / 5;
    padding: 0 8px;

    ${ContactCompanyDataHeader} {
      color: ${colors.secondary[20]};
      margin-bottom: 16px;
      span {
        display: block;
      }
    }

    ${ContactCompanyNipRegon} {
      padding: 0 8px;
      display: flex;
      gap: 32px;

      ${ContactCompanyNipRegonBox} {
        cursor: pointer;
        color: ${colors.secondary[20]};
        display: grid;
        gap: 4px;
        grid-template-columns: 1fr 16px;
      }
    }

    ${ContactCompanyBank} {
      cursor: pointer;
      padding: 0 8px;
      margin-top: 12px;
      color: ${colors.secondary[20]};
      display: grid;
      gap: 4px;
      grid-template-columns: auto 1fr;
      width: fit-content;
    }
  }

  ${ContactBranding} {
    margin: 6vh 0 6vh auto;
    grid-area: 4 / 1 / 5 / 5;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${ContactBrandingName} {
      margin-left: auto;
      color: ${colors.secondary[60]};
    }
  }

  ${devices.tabletPortrait} {
    ${ContactLetsTalk} {
      margin: 6vh 0;
      padding: 0;
      grid-column-end: 9;

      ${ContactLetsTalkGrid} {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        ${ContactLetsTalkBox} {
          padding: 0;

          ${ContactLetsTalkBoxData} {
            margin-top: 16px;
            gap: 8px;
          }

          ${ContactLetsTalkCommunicators} {
            margin: 32px 0;
          }

          ${ContactLetsTalkMaps} {
            margin: 32px 0;
          }
        }
      }
    }

    ${ContactForm} {
      grid-column-start: 2;
      grid-column-end: 8;
      margin: 6vh 0;
      padding: 40px;

      ${ContactFormInputContainer} {
        grid-template-columns: repeat(2, 1fr);
      }

      ${ContactFormTextArea} {
        margin-top: 24px;
      }

      ${ContactFormCheckboxContainer} {
        margin-top: 24px;
        gap: 16px;
      }

      ${ContactFormButtonSend} {
        margin-top: 24px;
      }
    }

    ${ContactCompanyData} {
      margin: 6vh 0;
      grid-column-start: 2;
      grid-column-end: 8;
      padding: 0;

      ${ContactCompanyDataHeader} {
        span {
          display: unset;
        }
        margin-bottom: 24px;
        text-align: center;
      }

      ${ContactCompanyNipRegon} {
        padding: 0;
        justify-content: center;

        ${ContactCompanyNipRegonBox} {
          gap: 8px;
        }
      }

      ${ContactCompanyBank} {
        padding: 0;
        gap: 8px;
        margin: 12px auto 0 auto;
      }
    }

    ${ContactBranding} {
      grid-column-end: 9;
    }
  }

  ${devices.tabletLandscape} {
    height: calc(100vh - 100px);
    ${ContactLetsTalk} {
      margin: 6vh 0 0 0;
      grid-column-start: 2;
      grid-column-end: 10;

      ${ContactLetsTalkGrid} {
        gap: 24px;

        ${ContactLetsTalkBox} {
          ${ContactLetsTalkCommunicators} {
            margin: 40px 0;
          }

          ${ContactLetsTalkMaps} {
            margin: 40px 0;
          }
        }
      }
    }

    ${ContactForm} {
      grid-area: 1 / 10 / 2 / 16;
      margin: 6vh 0;

      ${ContactFormButtonSend} {
        margin: 24px 0 0 auto;
      }
    }

    ${ContactCompanyData} {
      grid-area: 2 / 2 / 3 / 9;
      margin: auto 0;
      padding-bottom: 6vh;

      ${ContactCompanyDataHeader} {
        margin-bottom: 16px;
        text-align: left;
      }

      ${ContactCompanyNipRegon} {
        padding: 0;
        justify-content: unset;
      }

      ${ContactCompanyBank} {
        margin: 12px 0 0 0;
      }
    }

    ${ContactBranding} {
      margin-top: auto;
      grid-area: 2 / 9 / 3 / 16;
    }
  }
`;

export {
  Contact,
  ContactLetsTalk,
  ContactLetsTalkHeader,
  ContactLetsTalkGrid,
  ContactLetsTalkBox,
  ContactLetsTalkBoxData,
  ContactLetsTalkCommunicators,
  ContactLetsTalkCommunicatorsIcon,
  ContactLetsTalkMaps,
  ContactForm,
  ContactFormHeader,
  ContactFormInputContainer,
  ContactFormInput,
  ContactFormTextArea,
  ContactFormCheckboxContainer,
  ContactFormCheckbox,
  ContactFormCheckboxSelected,
  ContactFormButtonSend,
  ContactCompanyData,
  ContactCompanyDataHeader,
  ContactCompanyNipRegon,
  ContactCompanyNipRegonBox,
  ContactCompanyNipRegonBoxIcon,
  ContactCompanyBank,
  ContactBranding,
  ContactBrandingName,
  ContactBrandingIcon,
};
