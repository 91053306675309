import { useRef } from "react";
import anime from "animejs";
import { Grid, Typography, Breakpoint } from "design-system";
import * as Styles from "./FAQ.style";
import expandBlackIcon from "assets/common/expand-black_icon.svg";

// Open FAQ box animation
const closeFaqBox = (answer: Element, icon: Element) => {
  anime({
    targets: icon,
    rotateX: 0,
    duration: 450,
    easing: "easeInOutExpo",
  });

  anime({
    targets: answer,
    opacity: 0,
    height: 0,
    duration: 450,
    easing: "easeInOutExpo",
  });
};

const openFaqBox = (answer: Element, icon: Element) => {
  anime({
    targets: answer,
    opacity: 1,
    height: answer.children[0].clientHeight,
    duration: 450,
    easing: "easeInOutExpo",
  });

  anime({
    targets: icon,
    rotateX: 180,
    duration: 450,
    easing: "easeInOutExpo",
  });
};

const FAQSection = ({ content }: any) => {
  const faqBoxesRef: any = useRef<HTMLDivElement>(null);
  const handleQuestionClick = (e: any) => {
    const faqBoxesEl = faqBoxesRef.current;

    const answerEl = e.currentTarget.nextSibling;
    const answerElStyle = getComputedStyle(answerEl);
    const answerElState = answerElStyle.opacity;

    // Close app box
    const faqQuestionBoxes = [...faqBoxesEl.children];

    faqQuestionBoxes.map((box: Element) => {
      closeFaqBox(box.children[1], box.children[0].children[2]);
    });

    if (answerElState === "0") {
      openFaqBox(answerEl, e.currentTarget.children[2]);
      return;
    }

    closeFaqBox(answerEl, e.currentTarget.children[2]);
  };

  return (
    <Styles.FAQSection>
      <Grid>
        <Styles.FAQHeader>
          <Breakpoint ms m tp>
            <Typography type="header" variant="s" font="secondary">
              {content.faqHeader}
            </Typography>
          </Breakpoint>

          <Breakpoint tl>
            <Typography type="header" variant="m" font="secondary">
              {content.faqHeader}
            </Typography>
          </Breakpoint>
        </Styles.FAQHeader>

        <Styles.FAQBoxes ref={faqBoxesRef}>
          {content.faqQuestion.map(
            (box: { question: string; answer: string }, index: number) => (
              <Styles.FAQBox key={index}>
                <Styles.FAQBoxQuestionContainer onClick={handleQuestionClick}>
                  <Styles.FAQBoxQuestionContainerLine />

                  <Styles.FAQBoxQuestionContainerHeader>
                    <Breakpoint ms m tp>
                      <Typography type="subtitle" variant="inline" strong>
                        {box.question}
                      </Typography>
                    </Breakpoint>

                    <Breakpoint tl>
                      <Typography type="header" variant="xs" strong>
                        {box.question}
                      </Typography>
                    </Breakpoint>
                  </Styles.FAQBoxQuestionContainerHeader>

                  <Styles.FAQBoxQuestionContainerExpand src={expandBlackIcon} />
                </Styles.FAQBoxQuestionContainer>

                <Styles.FAQBoxAnswer>
                  <div>{box.answer}</div>
                </Styles.FAQBoxAnswer>
              </Styles.FAQBox>
            )
          )}
        </Styles.FAQBoxes>
      </Grid>
    </Styles.FAQSection>
  );
};

export { FAQSection };
