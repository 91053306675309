import styled from "styled-components";
import { colors, devices } from "design-system";

const SearchPhrase = styled.div``;
const SearchResult = styled.div``;
const SearchResultBox = styled.a``;
const SearchResultBoxImage = styled.img``;
const SearchResultBoxTitle = styled.div``;
const SearchResultBoxDescription = styled.div``;

const Search = styled.div`
  ${SearchPhrase} {
    grid-area: 1 / 1 / 2 / 5;
    margin-top: 4vh;
    text-align: center;
    .result {
      display: block;
      font-weight: 700;
    }
  }

  ${SearchResult} {
    grid-area: 2 / 1 / 3 / 5;
    padding: 0 8px;
    margin-bottom: 16vh;
    ${SearchResultBox} {
      display: block;
      margin-bottom: 40px;
      :first-child {
        margin-top: 56px;
      }
      :last-child {
        margin-bottom: 0;
      }

      ${SearchResultBoxImage} {
        width: 100%;
      }

      ${SearchResultBoxTitle} {
        margin: 8px 0;
      }

      ${SearchResultBoxDescription} {
        color: ${colors.primary[80]};
      }
    }
  }

  ${devices.tabletPortrait} {
    ${SearchPhrase} {
      grid-column-end: 9;
      margin-top: 6vh;

      ${SearchResultBox} {
        margin-bottom: 24px;
      }

      .result {
        display: unset;
      }
    }

    ${SearchResult} {
      grid-column-end: 9;
      ${SearchResultBox} {
        :first-child {
          margin-top: 40px;
        }

        display: grid;
        grid-template-columns: 33vw auto;
        grid-template-rows: auto 1fr;
        gap: 16px 24px;

        ${SearchResultBoxImage} {
          width: 100%;
          grid-area: 1 / 1 / 3 / 2;
        }

        ${SearchResultBoxTitle} {
          margin: 8px 0;
        }

        ${SearchResultBoxDescription} {
          color: ${colors.primary[80]};
        }
      }
    }
  }

  ${devices.tabletLandscape} {
    ${SearchPhrase} {
      grid-column-end: 17;
      margin-top: 10vh;
    }

    ${SearchResult} {
      grid-column-start: 4;
      grid-column-end: 14;
      ${SearchResultBox} {
        margin-bottom: 32px;
        :first-child {
          margin-top: 10vh;
        }

        grid-template-columns: 21.25vw auto;

        ${SearchResultBoxTitle} {
          margin: 20px 0 0 0;
        }

        ${SearchResultBoxDescription} {
          margin: 0 0 20px 0;
        }
      }
    }
  }
`;

export {
  Search,
  SearchPhrase,
  SearchResult,
  SearchResultBox,
  SearchResultBoxImage,
  SearchResultBoxTitle,
  SearchResultBoxDescription,
};
