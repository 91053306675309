import styled from "styled-components";
import { devices } from "design-system";

const PaginationButtons = styled.div``;
const PaginationButton = styled.img``;
const PaginationState = styled.div``;

const PaginationSection = styled.section`
  padding-bottom: 16vh;
  ${PaginationButtons} {
    grid-area: 1 / 1 / 2 / 5;
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${PaginationButton} {
      cursor: pointer;
      height: 52px;
    }
  }

  ${devices.tabletPortrait} {
    ${PaginationButtons} {
      grid-column-start: 3;
      grid-column-end: 7;
      padding: 0;

      ${PaginationButton} {
        height: 60px;
      }
    }
  }

  ${devices.tabletLandscape} {
    padding-bottom: 26vh;
    ${PaginationButtons} {
      grid-column-start: 1;
      grid-column-end: 17;
      justify-content: center;

      ${PaginationState} {
        margin-left: 96px;
        margin-right: 96px;
      }

      ${PaginationButton} {
        height: 64px;
      }
    }
  }
`;

export {
  PaginationState,
  PaginationButtons,
  PaginationButton,
  PaginationSection,
};
