import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Breakpoint, Typography } from "design-system";
import * as Styles from "./Search.style";
import { CTASection, Footer } from "components";
import content from "../articles/articles.content.json";
import axios from "axios";

const listSectionContent = content.section.list;

const Search = ({ language, acfId }: any) => {
  const { phrase } = useParams<{ phrase: string }>();

  // States
  const [searchPhrase, setSearchPhrase] = useState(phrase);
  const [posts, setPosts] = useState(Array);
  const [result, setResult] = useState(Array);
  const [content, setContent]: any = useState({});

  // Fetch content
  useEffect(() => {
    axios
      .get(
        `https://cms.maciejtrojanowski.com/wp-json/acf/v3/pages/${acfId}?lang=${language}`
      )
      .then(function (res) {
        setContent(res.data.acf);
      });
  }, [language]);

  // Refs
  const searchResultBoxesRef: any = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPosts(listSectionContent.articles);
    setSearchPhrase(phrase);

    let unfilteredArticles = [];

    unfilteredArticles = posts.filter((article: any) => {
      const title = article.title.toLowerCase();
      const description = article.description.toLowerCase();

      if (description.search(searchPhrase) !== -1)
        return description.search(searchPhrase) !== -1;
      return title.search(searchPhrase) !== -1;
    });

    setResult(unfilteredArticles);
  }, [posts, searchPhrase]);

  useEffect(() => {
    const searchResultBoxesEl: any = searchResultBoxesRef.current;
    const searchBoxes = [...searchResultBoxesEl.children];

    searchBoxes.map((box) => {
      const title = box.children[1].textContent;
      const description = box.children[2].textContent;

      const newTitle = title.replaceAll(
        searchPhrase,
        `<strong>${searchPhrase}</strong>`
      );
      const newDescription = description.replaceAll(
        searchPhrase,
        `<strong>${searchPhrase}</strong>`
      );

      box.children[1].children[0].innerHTML = newTitle;
      box.children[2].innerHTML = newDescription;
    });
  }, [result]);

  return (
    <>
      <Styles.Search>
        <Grid>
          <Styles.SearchPhrase>
            <Breakpoint ms m tl>
              <Typography tag="span" type="header" variant="s" font="secondary">
                {content.header}
              </Typography>

              <Typography
                className="result"
                tag="span"
                type="header"
                variant="s"
                font="secondary"
                strong
              >
                {` “${searchPhrase}“`}
              </Typography>
            </Breakpoint>

            <Breakpoint tp>
              <Typography tag="span" type="header" variant="m">
                {content.header}
              </Typography>

              <Typography
                className="result"
                tag="span"
                type="header"
                variant="m"
                strong
              >
                {` “${searchPhrase}“`}
              </Typography>
            </Breakpoint>
          </Styles.SearchPhrase>

          <Styles.SearchResult ref={searchResultBoxesRef}>
            {result.map((article: any) => (
              <Styles.SearchResultBox
                href={`${process.env.PUBLIC_URL}/artykuly/${article.slug}`}
                key={article.id}
              >
                <Styles.SearchResultBoxImage src={article.thumbnail} />
                <Styles.SearchResultBoxTitle>
                  <Typography type="header" variant="s" font="secondary">
                    {article.title}
                  </Typography>
                </Styles.SearchResultBoxTitle>

                <Styles.SearchResultBoxDescription>
                  <>{article.description}</>
                </Styles.SearchResultBoxDescription>
              </Styles.SearchResultBox>
            ))}
          </Styles.SearchResult>
        </Grid>

        <CTASection />
      </Styles.Search>

      <Footer />
    </>
  );
};

export { Search };
