import styled from "styled-components";
import { ButtonStyle, devices, GridStyles } from "design-system";

const HeroImageWrapper = styled.div``;
const HeroImageLine = styled.img``;
const HeroTextContainer = styled.div``;
const HeroHeader = styled.div``;
const HeroDescription = styled.div``;
const HeroButton = styled.div``;

const SecondDescription = styled.div``;
const TertiaryDescription = styled.div``;
const SecondLine = styled.img``;

const HeroSection = styled.section`
  ${HeroImageWrapper} {
    grid-area: 1 / 1 / 2 / 4;
    position: relative;
    width: calc(100% + 32px);
    display: flex;
    .hero-image {
      margin-top: auto;
      margin-left: -16px;
    }
    ${HeroImageLine} {
      position: absolute;
      width: calc(100vw - 16px);
      left: 0;
      bottom: 0;
    }
  }

  ${HeroTextContainer} {
    grid-area: 2 / 1 / 3 / 5;
    margin-top: 4vh;
    padding: 0 8px 0 32px;
    z-index: 2;
    ${HeroDescription} {
      margin: 16px 0;
    }
    ${HeroButton} {
      margin-left: auto;
      width: fit-content;
      ${ButtonStyle.Button} {
        padding-left: 32px;
        padding-right: 32px;
      }
    }
  }

  ${SecondDescription} {
    grid-area: 3 / 2 / 4 / 5;
    margin-top: 14vh;
  }

  .second-image {
    grid-area: 4 / 2 / 5 / 5;
    width: calc(100% + 16px);
    margin: 24px 0;
  }

  ${TertiaryDescription} {
    grid-area: 5 / 1 / 6 / 5;
    padding-left: 32px;
  }

  ${SecondLine} {
    grid-area: 3 / 1 / 6 / 1;
    position: relative;
    width: calc(100% + 32px);
    margin-left: -16px;
  }

  ${devices.tabletPortrait} {
    ${HeroImageWrapper} {
      width: calc(100% + 32px);
      grid-area: 2 / 5 / 3 / 9;
      .hero-image {
        margin-top: 0;
        margin-left: 0;
      }
    }

    ${HeroImageLine} {
      grid-area: 1 / 7 / 2 / 9;
      margin-top: calc(-4vh - 4vh - 32px - 32px);
      height: 32vh;
      width: calc(100% + 32px);
      object-fit: contain;
    }

    ${HeroTextContainer} {
      padding-right: 0;
      padding-left: 8px;
      margin-top: 0;
      grid-area: 1 / 1 / 2 / 7;

      ${HeroHeader} {
        padding-right: 0;
      }

      ${HeroDescription} {
        margin: 32px 0;
      }

      ${HeroButton} {
        margin-left: 0;
      }
    }

    ${SecondDescription} {
      grid-area: 3 / 3 / 4 / 9;
      margin-top: 10vh;
      position: relative;
      z-index: 2;
    }

    .second-image {
      grid-area: 4 / 4 / 5 / 9;
      margin-top: 6vh;
      margin-bottom: 0;
      width: calc(100% + 32px);
      position: relative;
      z-index: 4;
    }

    ${TertiaryDescription} {
      grid-column-end: 8;
      margin-top: 52px;
      padding-left: 16px;
    }

    ${SecondLine} {
      margin-top: -14vh;
      margin-left: 0;
      grid-area: 3 / 2 / 6 / 9;
    }
  }

  ${devices.tabletLandscape} {
    ${GridStyles.Grid} {
      grid-template-rows: calc(100vh - 4vh - 4vh - 32px) 1fr auto;
    }

    ${HeroImageWrapper} {
      grid-area: 1 / 1 / 2 / 7;
      width: calc(100% + 20px);
      .hero-image {
        margin-left: -20px;
        height: calc(100vh - 10vh - 4vh - 4vh - 32px);
      }
    }

    ${HeroImageLine} {
      margin-left: -20px;
      width: calc(100% + 20px);
      height: unset;
      grid-area: 1 / 1 / 2 / 8;
      z-index: 1;
    }

    ${HeroTextContainer} {
      grid-column-start: 9;
      grid-column-end: 15;
      padding-left: 0;
      margin-top: auto;
      margin-bottom: 16vh;

      ${HeroButton} {
        margin-left: auto;
      }
    }

    ${SecondDescription} {
      grid-area: 2 / 7 / 3 / 13;
      margin: 26vh 0 16vh 0;
    }

    .second-image {
      grid-area: 3 / 11 / 4 / 17;
      margin: auto 0 0 0;
      width: calc(100% + 20px);
    }

    ${TertiaryDescription} {
      grid-area: 3 / 3 / 4 / 9;
      margin-top: auto;
      margin-bottom: auto;
    }

    ${SecondLine} {
      grid-area: 1 / 5 / 6 / 17;
      margin-top: calc(26vh - 4vh - 4vh - 32px);
    }
  }
`;

export {
  HeroSection,
  HeroImageWrapper,
  HeroImageLine,
  HeroTextContainer,
  HeroHeader,
  HeroDescription,
  HeroButton,
  SecondDescription,
  TertiaryDescription,
  SecondLine,
};
