import { useEffect, useRef, useState } from "react";
import anime from "animejs";
import {
  Button,
  Grid,
  Typography,
  Breakpoint,
  useClickOutside,
} from "design-system";
import * as Styles from "./Help.style";

import expandBlackIcon from "assets/home/dropdown-expand-black_icon.svg";
import checkCircleGrayIcon from "assets/common/check-circle-gray_icon.svg";

const dropdownOpen = ({ options, height, expand }: any) => {
  expand.classList.add("open");

  anime({
    targets: options,
    height: [0, height],
    opacity: 1,
    duration: 400,
    easing: "easeInOutCirc",
  });

  anime({
    targets: [...options.children],
    opacity: 1,
    scale: [0.7, 1],
    duration: 400,
    delay: anime.stagger(50),
    easing: "easeInOutCirc",
  });
};

const dropdownClose = ({ options, expand }: any) => {
  expand.classList.remove("open");

  anime({
    targets: options,
    height: 0,
    opacity: 0,
    duration: 400,
    easing: "easeInOutCirc",
  });

  anime({
    targets: [...options.children],
    opacity: 0,
    scale: [1, 0.7],
    duration: 400,
    delay: anime.stagger(50),
    easing: "easeInOutCirc",
  });
};

const HelpSection = ({ content }: any) => {
  // Refs
  const dropdownOptionsRef = useRef<HTMLDivElement>(null);
  const dropdownLabelRef = useRef<HTMLDivElement>(null);
  const dropdownExpandRef = useRef<HTMLImageElement>(null);

  // States
  const [dropdownLabel, setDropdownLabel] = useState(
    content.helpDropdown.options[0]
  );
  const [dropdownOptionsHeight, setDropdownOptionsHeight] = useState(0);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [helpContext, setHelpContext] = useState(
    content.helpDropdown.options[0].context
  );

  useEffect(() => {
    setTimeout(() => {
      const dropdownOptionsEl: any = dropdownOptionsRef.current;
      setDropdownOptionsHeight(dropdownOptionsEl.clientHeight);
    }, 1);
  }, []);

  // Dropdown
  const handleDropdownClick = () => {
    if (dropdownIsOpen) return;
    setDropdownIsOpen(true);

    dropdownOpen({
      options: dropdownOptionsRef.current,
      expand: dropdownExpandRef.current,
      height: dropdownOptionsHeight,
    });
  };

  useClickOutside(dropdownLabelRef, () => {
    setDropdownIsOpen(false);

    dropdownClose({
      options: dropdownOptionsRef.current,
      expand: dropdownExpandRef.current,
    });
  });

  // Dropdown options
  const handleDropdownOptionClick = (label: string) => {
    content.helpDropdown.options.map(
      (option: { label: string; context: Array<{ label: string }> }) => {
        if (option.label === label) {
          setDropdownLabel(option);
          setHelpContext(option.context);
        }
      }
    );
  };

  // JSX content
  return (
    <Styles.HelpSection>
      <Grid>
        <Styles.Help>
          <Styles.HelpHeader>
            <Typography type="header" variant="s" font="secondary">
              {content.helpHeader}
            </Typography>
          </Styles.HelpHeader>

          <Styles.HelpDropdown>
            <Styles.HelpDropdownLabelWrapper
              ref={dropdownLabelRef}
              onClick={handleDropdownClick}
            >
              <Styles.HelpDropdownLabel>
                <Styles.Icon src={dropdownLabel.icon} />

                <Styles.Label>
                  <Breakpoint ms m>
                    <>{dropdownLabel.label}</>
                  </Breakpoint>

                  <Breakpoint tp tl>
                    <Typography type="subtitle" variant="inline">
                      {dropdownLabel.label}
                    </Typography>
                  </Breakpoint>
                </Styles.Label>
              </Styles.HelpDropdownLabel>

              <Styles.HelpDropdownExpand
                ref={dropdownExpandRef}
                src={expandBlackIcon}
              />
            </Styles.HelpDropdownLabelWrapper>

            <Styles.HelpDropdownOptions ref={dropdownOptionsRef}>
              {content.helpDropdown.options.map(
                (option: any, index: number) => (
                  <Styles.HelpDropdownOption
                    key={index}
                    onClick={() => handleDropdownOptionClick(option.label)}
                  >
                    <Styles.Icon src={option.icon} />
                    <Styles.Label>
                      <Breakpoint ms m>
                        <>{option.label}</>
                      </Breakpoint>
                      <Breakpoint tp tl>
                        <Typography type="subtitle" variant="inline">
                          {option.label}
                        </Typography>
                      </Breakpoint>
                    </Styles.Label>
                  </Styles.HelpDropdownOption>
                )
              )}
            </Styles.HelpDropdownOptions>
          </Styles.HelpDropdown>

          <Styles.HelpContext>
            {helpContext.map((box: { label: string }, index: number) => (
              <Styles.HelpContextBox key={index}>
                <Styles.HelpContextBoxIcon src={checkCircleGrayIcon} />
                <>{box.label}</>
              </Styles.HelpContextBox>
            ))}
          </Styles.HelpContext>

          <Styles.HelpLanguages>
            <Styles.HelpLanguageHeader>
              <>{content.helpAvailableLanguageDesc}</>
            </Styles.HelpLanguageHeader>

            <Styles.HelpLanguageList>
              {content.helpAvailableLanguage.map(
                (language: { icon: string; label: string }, index: number) => (
                  <Styles.HelpLanguageBox key={index}>
                    <Styles.HelpLanguageBoxIcon src={language.icon} />
                    <>{language.label}</>
                  </Styles.HelpLanguageBox>
                )
              )}
            </Styles.HelpLanguageList>
          </Styles.HelpLanguages>

          <Styles.HelpButtons>
            <Button variant="black" width="fit" href={content.helpButton.link}>
              <Typography type="button">{content.helpButton.label}</Typography>
            </Button>
            <Button
              width="fit"
              variant="tertiary"
              href={content.helpButton2.link}
            >
              <Typography type="button">{content.helpButton2.label}</Typography>
            </Button>
          </Styles.HelpButtons>
        </Styles.Help>
      </Grid>
    </Styles.HelpSection>
  );
};

export { HelpSection };
