import styled from "styled-components";
import { GridStyles, colors, devices } from "design-system";

const Popup = styled.div``;
const PopupHeaderContainer = styled.div``;
const PopupHeader = styled.div``;
const PopupHeaderIcon = styled.img``;
const PopupBoxes = styled.div``;
const PopupBox = styled.div``;
const PopupBoxIcon = styled.img``;
const PopupBoxLabel = styled.div``;
const PopupContent = styled.div``;

const PopupContainer = styled.div`
  background-color: rgba(0, 0, 0, 0);
  z-index: -1;
  position: fixed;
  width: 100vw;
  height: 100vh !important;
  left: 0;
  top: 0;
  display: flex;

  ${GridStyles.GridContainer} {
    margin: auto 0;
    height: fit-content;
    ${Popup} {
      top: 100%;
      opacity: 0;
      position: relative;
      margin: auto 0;
      grid-area: 1 / 1 / 2 / 5;
      padding: 24px 20px 32px 20px;
      background-color: ${colors.white};
      border: 1px solid ${colors.secondary[60]};
      ${PopupHeaderContainer} {
        display: grid;
        grid-template-columns: 1fr 20px;
        margin-bottom: 16px;

        ${PopupHeaderIcon} {
          cursor: pointer;
        }

        ${PopupHeader} {
          width: calc(100% + 20px);
        }
      }

      ${PopupBoxes} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: fit-content;
        margin: 0 auto;
        color: ${colors.primary[80]};
        ${PopupBox} {
          margin-top: 20px;
          flex: 0 0 33.333333%;
          ${PopupBoxIcon} {
            width: 24px;
            margin-bottom: 4px;
          }
        }
      }

      ${PopupContent} {
        margin-top: 20px;
        color: ${colors.primary[80]};
      }
    }
  }

  ${devices.tabletPortrait} {
    ${GridStyles.GridContainer} {
      ${Popup} {
        grid-column-start: 2;
        grid-column-end: 8;
        padding: 32px 32px 36px 32px;
        ${PopupHeaderContainer} {
          margin-bottom: 12px;
        }

        ${PopupBoxes} {
          ${PopupBox} {
            margin-top: 28px;
          }
        }

        ${PopupContent} {
          margin-top: 40px;
        }
      }
    }
  }

  ${devices.tabletLandscape} {
    ${GridStyles.GridContainer} {
      ${Popup} {
        grid-column-start: 5;
        grid-column-end: 13;
        padding: 40px 32px 44px 32px;
        ${PopupHeaderContainer} {
          margin-bottom: 20px;
        }

        ${PopupBoxes} {
          ${PopupBox} {
            margin-top: 40px;
            ${PopupBoxIcon} {
              width: 32px;
              margin-bottom: 8px;
            }
          }
        }

        ${PopupContent} {
          margin-top: 60px;
        }
      }
    }
  }
`;

export {
  PopupContainer,
  Popup,
  PopupHeaderContainer,
  PopupHeader,
  PopupHeaderIcon,
  PopupBoxes,
  PopupBox,
  PopupBoxIcon,
  PopupBoxLabel,
  PopupContent,
};
