import { useEffect, useRef, useState } from "react";
import anime from "animejs";
import {
  Grid,
  Typography,
  useClickOutside,
  Breakpoint,
  colors,
} from "design-system";
import * as Styles from "./Hero.style";
import expandBlackIcon from "assets/common/expand-black_icon.svg";
import articlesContent from "../../articles.content.json";

const content = articlesContent.section.hero;
const allArticles = articlesContent.section.list.articles;

let openDropdownDisable: boolean = false;
const DURATION = 400;
const EASE = "easeInOutCirc";

const openDropdown = (
  dropdown: HTMLDivElement | null,
  dropdownOptions: HTMLDivElement | any,
  dropdownExpand: any
) => {
  if (openDropdownDisable) return;
  openDropdownDisable = true;

  dropdownExpand.classList.add("open");

  dropdownOptions.style.zIndex = 2;

  anime({
    targets: dropdown,
    width: ["76%", "100%"],
    duration: DURATION,
    easing: EASE,
  });

  anime({
    targets: dropdownOptions,
    opacity: 1,
    duration: DURATION,
    easing: EASE,
  });

  anime({
    targets: [...dropdownOptions.children],
    scale: [0.4, 1],
    duration: DURATION / 2,
    delay: anime.stagger(50, { start: 75 }),
    easing: EASE,
  });
};

const closeDropdown = (
  dropdown: HTMLDivElement | null,
  dropdownOptions: HTMLDivElement | any,
  dropdownExpand: any
) => {
  openDropdownDisable = false;

  dropdownExpand.classList.remove("open");

  anime({
    targets: dropdown,
    width: "76%",
    duration: DURATION,
    easing: EASE,
  });

  anime({
    targets: dropdownOptions,
    opacity: 0,
    duration: DURATION,
    easing: EASE,
    complete: () => {
      if (dropdownOptions) dropdownOptions.style.zIndex = -1;
    },
  });

  anime({
    targets: [...dropdownOptions.children],
    scale: [1, 0],
    duration: DURATION / 2,
    delay: anime.stagger(50),
    easing: EASE,
  });
};

let selectedFilter: Array<string> = [];
let filteredArticles;
const filterArticlesByCategory = ({
  box,
  label,
  color,
  articles,
  setArticles,
}: any) => {
  if (box.dataset.type === "all") return;
  if (label === content.category[0].label) return;

  const boxStyle = getComputedStyle(box);
  const whiteRGB = "rgb(255, 255, 255)";

  if (boxStyle.color !== whiteRGB) {
    selectedFilter.push(label.toLowerCase());

    filteredArticles = allArticles.filter((article: any) => {
      const category = article.category.toLowerCase();

      return selectedFilter.includes(category);
    });

    setArticles(filteredArticles);

    return anime({
      targets: box,
      backgroundColor: color.primary[60],
      borderColor: color.primary[60],
      color: color.white,
      duration: DURATION * 0.75,
      easing: EASE,
    });
  }

  const index = selectedFilter.indexOf(label.toLowerCase());
  if (index > -1) {
    selectedFilter.splice(index, 1);
  }

  filteredArticles = articles.filter((article: any) => {
    const category = article.category.toLowerCase();

    return selectedFilter.includes(category);
  });

  setArticles(filteredArticles);
  if (filteredArticles.length === 0) setArticles(allArticles);

  return anime({
    targets: box,
    backgroundColor: color.secondary[100],
    borderColor: color.primary[100],
    color: color.primary[60],
    duration: 300,
    easing: "easeInOutCirc",
  });
};

const HeroSection = ({ setArticles, articles }: any) => {
  // Refs
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownOptionsRef = useRef<HTMLDivElement>(null);
  const dropdownExpandRef = useRef<HTMLImageElement>(null);

  // State
  const [categories, setCategories] = useState(content.category);

  // Filter category
  useEffect(() => {
    if (articles.length === allArticles.length) {
      let nonEmptyCategory: Array<string> = [];
      let uniqueCategory: Array<{ id: number; label: string }> = [];

      categories.map((category: { label: string }) => {
        articles.map((article: any) => {
          if (category.label.toLowerCase() === article.category.toLowerCase()) {
            nonEmptyCategory.push(category.label);
          }
        });
      });

      [...new Set(nonEmptyCategory)].map((uniqueCat: string, index: number) => {
        uniqueCategory.push({ id: index, label: uniqueCat });
      });

      if (uniqueCategory.length === 0) return;
      setCategories(uniqueCategory);
    }
  }, [articles]);

  // Handle dropdown click
  const handleDropdownClick = () => {
    const dropdownEl = dropdownRef.current;
    const dropdownOptionsEl = dropdownOptionsRef.current;
    const dropdownExpandEl = dropdownExpandRef.current;

    openDropdown(dropdownEl, dropdownOptionsEl, dropdownExpandEl);
  };

  // Close dropdown on clock outside
  useClickOutside(dropdownRef, () => {
    const dropdownEl: any = dropdownRef.current;
    const dropdownOptionsEl: any = dropdownOptionsRef.current;
    const dropdownExpandEl = dropdownExpandRef.current;

    closeDropdown(dropdownEl, dropdownOptionsEl, dropdownExpandEl);
  });

  // Handle category click
  const handleCategoryClick = (e: any) => {
    const box = e.currentTarget;
    const boxLabel = box.children[0].textContent;

    filterArticlesByCategory({
      box: box,
      label: boxLabel,
      color: colors,
      articles: articles,
      setArticles: setArticles,
    });
  };

  const handleAllCategoryClick = () => {
    anime({
      targets: ".category",
      backgroundColor: colors.secondary[100],
      borderColor: colors.primary[100],
      color: colors.primary[60],
      duration: 300,
      easing: "easeInOutCirc",
    });

    selectedFilter = [];
    setArticles(allArticles);
  };

  return (
    <Styles.HeroSection>
      <Grid>
        <Styles.HeroCategories>
          <Styles.HeroCategoriesCarousel>
            <Styles.HeroCategoriesLabel>
              <Typography type="header" variant="s" font="secondary">
                Kategorie
              </Typography>
            </Styles.HeroCategoriesLabel>

            <Styles.HeroCategoriesBoxes>
              <Styles.HeroCategoriesBox
                onClick={handleAllCategoryClick}
                data-type="all"
              >
                <Typography type="button" variant="small">
                  {content.category[0].label}
                </Typography>
              </Styles.HeroCategoriesBox>

              {categories.map((cat) => (
                <Styles.HeroCategoriesBox
                  className="category"
                  onClick={handleCategoryClick}
                  key={cat.id}
                >
                  <Typography type="button" variant="small">
                    {cat.label}
                  </Typography>
                </Styles.HeroCategoriesBox>
              ))}

              <Styles.HeroCategoriesBox />
            </Styles.HeroCategoriesBoxes>
          </Styles.HeroCategoriesCarousel>
        </Styles.HeroCategories>

        <Styles.HeroHeader>
          <Typography type="header" variant="xxl" font="secondary">
            Artykuły
          </Typography>
        </Styles.HeroHeader>

        <Styles.HeroDescription>
          <Breakpoint ms m>
            <>{content.description}</>
          </Breakpoint>

          <Breakpoint tp tl>
            <Typography type="subtitle">{content.description}</Typography>
          </Breakpoint>
        </Styles.HeroDescription>

        <Styles.HeroDropdown onClick={handleDropdownClick} ref={dropdownRef}>
          <Styles.HeroDropdownLabelContainer>
            <Styles.HeroDropdownLabel>
              <Typography type="header" variant="s" font="secondary">
                Kategorie
              </Typography>
            </Styles.HeroDropdownLabel>

            <Styles.HeroDropdownExpand
              ref={dropdownExpandRef}
              src={expandBlackIcon}
            />
          </Styles.HeroDropdownLabelContainer>

          <Styles.HeroDropdownOptions ref={dropdownOptionsRef}>
            <Styles.HeroDropdownOption
              data-type="all"
              onClick={handleAllCategoryClick}
            >
              <Typography type="button" variant="small">
                {content.category[0].label}
              </Typography>
            </Styles.HeroDropdownOption>

            {categories.map((cat: any) => (
              <Styles.HeroDropdownOption
                className="category"
                onClick={handleCategoryClick}
                key={cat.id}
              >
                <Typography type="button" variant="small">
                  {cat.label}
                </Typography>
              </Styles.HeroDropdownOption>
            ))}
          </Styles.HeroDropdownOptions>
        </Styles.HeroDropdown>
      </Grid>
    </Styles.HeroSection>
  );
};

export { HeroSection };
