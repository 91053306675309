import { useRef } from "react";
import anime from "animejs";
import { Grid, Typography, Breakpoint, useWindowSize } from "design-system";

import * as Styles from "./Opinion.style";

import arrowLeftIcon from "assets/common/arrow-left-black_icon.svg";
import arrowRightIcon from "assets/common/arrow-right-black_icon.svg";

const OpinionSection = ({ content }: any) => {
  const { width } = useWindowSize();

  // Opinion carousel
  const opinionRef: any = useRef<HTMLDivElement>(null);
  let index: number = 0;

  // Handle click
  const handleOpinionClick = (e: any) => {
    const opinionEl = opinionRef.current;

    const direction: string = e.currentTarget.dataset.direction;
    const opinionWidth = opinionEl.children[0].getBoundingClientRect().width;
    const opinionsLength = [...opinionEl.children].length;

    // Get direction
    if (direction === "left") {
      if (index === 0) return;
      index--;
    }

    if (direction === "right") {
      if (index === opinionsLength - 1) return;
      index++;
    }

    // Animation
    opinionEl.style.scrollSnapType = "none ";

    let marginLeft: number = 16;
    if (width >= 600) marginLeft = 32;
    if (width >= 900) marginLeft = 24;

    anime({
      targets: opinionEl,
      scrollLeft: (opinionWidth + marginLeft) * index,
      duration: 1000,
      easing: "easeInOutCubic",
      complete: () => (opinionEl.style.scrollSnapType = "x mandatory"),
    });
  };

  return (
    <Styles.OpinionSection>
      <Grid>
        <Styles.OpinionCarousel ref={opinionRef}>
          {content.opinions.map(
            (opinion: { content: string; author: string }, index: number) => (
              <Styles.OpinionBox key={index}>
                <Styles.OpinionBoxContent>
                  <Breakpoint ms m>
                    <>{opinion.content}</>
                  </Breakpoint>

                  <Breakpoint tp tl>
                    <Typography type="header" variant="xs">
                      {opinion.content}
                    </Typography>
                  </Breakpoint>
                </Styles.OpinionBoxContent>

                <Styles.OpinionBoxHeader>
                  <Typography type="header" variant="s" font="secondary">
                    {opinion.author}
                  </Typography>
                </Styles.OpinionBoxHeader>
              </Styles.OpinionBox>
            )
          )}
        </Styles.OpinionCarousel>

        <Styles.OpinionArrows>
          <Styles.OpinionArrow
            onClick={handleOpinionClick}
            data-direction="left"
            src={arrowLeftIcon}
          />
          <Styles.OpinionArrow
            onClick={handleOpinionClick}
            data-direction="right"
            src={arrowRightIcon}
          />
        </Styles.OpinionArrows>
      </Grid>
    </Styles.OpinionSection>
  );
};

export { OpinionSection };
