import styled from "styled-components";
import { colors, devices } from "design-system";

const HeroHeader = styled.div``;
const HeroDescription = styled.div``;
const HeroDropdown = styled.div``;
const HeroDropdownLabelContainer = styled.div``;
const HeroDropdownLabel = styled.div``;
const HeroDropdownExpand = styled.img``;
const HeroDropdownOptions = styled.div``;
const HeroDropdownOption = styled.div``;

const HeroCategories = styled.div``;
const HeroCategoriesCarousel = styled.div``;
const HeroCategoriesLabel = styled.div``;
const HeroCategoriesBoxes = styled.div``;
const HeroCategoriesBox = styled.div``;

const HeroSection = styled.div`
  padding: 4vh 0;
  ${HeroCategories} {
    display: none;
  }

  ${HeroHeader} {
    grid-area: 1 / 1 / 2 / 5;
    padding: 0 16px;
  }

  ${HeroDescription} {
    grid-area: 2 / 1 / 3 / 5;
    padding: 0 16px;
    margin-top: 8px;
    color: ${colors.secondary[20]};
  }

  ${HeroDropdown} {
    position: relative;
    margin: 4vh 0 4vh auto;
    width: 76%;
    grid-area: 3 / 1 / 4 / 5;

    ${HeroDropdownLabelContainer} {
      padding: 12px 32px;
      background-color: ${colors.secondary[100]};
      display: flex;
      justify-content: space-between;
      align-items: center;

      ${HeroDropdownExpand} {
        transform: rotateZ(0deg);
        transition: all 0.2s ease-out;

        &.open {
          transform: rotateZ(-180deg);
        }
      }
    }

    ${HeroDropdownOptions} {
      position: absolute;
      width: 100%;
      opacity: 0;
      padding: 16px 16px 16px 32px;
      background-color: ${colors.secondary[100]};
      flex-flow: row wrap;
      flex-wrap: wrap;
      flex-direction: row;
      display: flex;
      gap: 16px;

      ${HeroDropdownOption} {
        :first-child {
          color: ${colors.white};
          background-color: ${colors.primary[60]};
          border: 1px solid ${colors.primary[60]};
        }

        max-width: fit-content;
        border-radius: 4px;
        padding: 8px 12px;
        color: ${colors.primary[60]};
        background-color: transparent;
        border: 1px solid ${colors.primary[100]};
      }
    }
  }

  ${devices.tabletPortrait} {
    ${HeroCategories} {
      grid-area: 1 / 1 / 2 / 9;
      display: block;
      width: calc(100% + 32px);
      background-color: ${colors.secondary[100]};
      padding: 32px 0;
      margin-bottom: 4vh;

      ${HeroCategoriesCarousel} {
        overflow-x: auto;
        display: flex;
        align-items: center;

        ${HeroCategoriesLabel} {
          margin: 0 40px;
        }

        ${HeroCategoriesBoxes} {
          display: flex;
          gap: 24px;

          ${HeroCategoriesBox} {
            :first-child {
              color: ${colors.white};
              background-color: ${colors.primary[60]};
              border: 1px solid ${colors.primary[60]};
            }

            :last-child {
              border: none;
              padding: 1px;
            }

            min-width: fit-content;
            border-radius: 4px;
            padding: 8px 12px;
            color: ${colors.primary[60]};
            background-color: transparent;
            border: 1px solid ${colors.primary[100]};
          }
        }
      }
    }

    ${HeroHeader} {
      grid-area: 2 / 1 / 3 / 8;
      padding: 0 0 0 16px;

      * {
        font-size: 104px;
      }
    }

    ${HeroDescription} {
      grid-area: 3 / 1 / 4 / 8;
      padding: 0 0 0 16px;
      margin-top: 24px;
    }

    ${HeroDropdown} {
      display: none;
    }
  }

  ${devices.tabletLandscape} {
    margin: 10vh 0;

    ${HeroCategories} {
      grid-area: 1 / 9 / 3 / 17;
      height: fit-content;
      width: calc(100% + 20px);
      padding: 48px 64px 64px 80px;
      margin: auto 0 0 0;

      ${HeroCategoriesCarousel} {
        overflow-x: hidden;
        display: block;

        ${HeroCategoriesLabel} {
          margin: 0 0 24px 0;
        }

        ${HeroCategoriesBoxes} {
          cursor: pointer;
          flex-flow: row wrap;
          flex-wrap: wrap;
          flex-direction: row;
          gap: 16px;
        }
      }
    }

    ${HeroHeader} {
      grid-area: 1 / 3 / 2 / 8;
      padding: 0;
    }

    ${HeroDescription} {
      grid-area: 2 / 3 / 3 / 8;
      padding: 0;
    }
  }
`;

export {
  HeroSection,
  HeroHeader,
  HeroDescription,
  HeroDropdown,
  HeroDropdownLabelContainer,
  HeroDropdownLabel,
  HeroDropdownExpand,
  HeroDropdownOptions,
  HeroDropdownOption,
  HeroCategories,
  HeroCategoriesCarousel,
  HeroCategoriesLabel,
  HeroCategoriesBoxes,
  HeroCategoriesBox,
};
