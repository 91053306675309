import React from "react";
import { typography } from "../../variables";
import * as Styles from "./Typography.style";
var Typography = function (props) {
    var hrefTag = props.href ? "a" : "div";
    var tag = props.tag ? props.tag : hrefTag;
    var variant = props.variant ? props.variant : "default";
    var styles = typography[props.type][variant];
    var font = props.font ? typography.font[props.font] : typography.font.primary;
    return (React.createElement(Styles.Typography, { as: tag, strong: props.strong, styles: styles, className: props.className, href: props.href, font: font, color: props.color, dangerouslySetInnerHTML: { __html: props.children } }));
};
export { Typography };
