import { useEffect, useRef, useState } from "react";
import anime from "animejs";
import {
  Button,
  Grid,
  Typography,
  useClickOutside,
  Breakpoint,
  useWindowSize,
} from "design-system";
import { NavTypes } from "./Nav.type";
import * as Styles from "./Nav.style";
import content from "./nav.content.json";

import logoBlack from "assets/common/logo/horizontal-logo-black.svg";
import searchIcon from "assets/common/search.svg";
import searchGrayIcon from "assets/common/search-gray.svg";
import menuIcon from "assets/common/menu.svg";
import expandIcon from "assets/common/expand.svg";
import closeSearchIcon from "assets/common/close-search.svg";
import closeMenuIcon from "assets/menu/close.svg";

const getCurrentLanguage = (current: string) => {
  let currentLanguage;

  content.languages.map((language) => {
    if (language.code === current) {
      currentLanguage = {
        id: language.id,
        label: language.label,
        code: language.code,
        slug: language.slug,
        icon: language.icon,
      };
    }
  });

  return currentLanguage;
};

const Nav = ({ pages }: NavTypes) => {
  // Refs
  const navCurrentLanguageRef = useRef<HTMLDivElement>(null);
  const navLanguageDropdownRef = useRef<HTMLDivElement>(null);
  const navSearchRef = useRef<HTMLDivElement>(null);
  const navSearchIconRef = useRef<HTMLImageElement>(null);
  const navLogoRef = useRef<HTMLImageElement>(null);
  const navMenuRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // Hooks
  const { width } = useWindowSize();

  // States
  const [currentPage, setCurrentPage] = useState("");
  const [currentLanguage, setCurrentLanguage]: any = useState<{
    id: number;
    label: string;
    code: string;
    slug: string;
    icon: string;
  }>(content.languages[0]);

  useEffect(() => {
    const location = window.location;
    const splitPathname = location.pathname.split("/");
    const locationLanguage = splitPathname[1];

    if (locationLanguage !== "en" && locationLanguage !== "uk")
      localStorage.setItem("language", "pl");
    if (locationLanguage === "en") localStorage.setItem("language", "en");
    if (locationLanguage === "uk") localStorage.setItem("language", "uk");

    const storageLanguage: any = localStorage.getItem("language");
    setCurrentLanguage(getCurrentLanguage(storageLanguage));

    if (storageLanguage === "pl") {
      setCurrentPage(
        `${splitPathname[1] ? `/${splitPathname[1]}` : ""}${
          splitPathname[2] ? `/${splitPathname[2]}` : ""
        }`
      );
    } else {
      setCurrentPage(
        `${splitPathname[2] ? `/${splitPathname[2]}` : ""}${
          splitPathname[3] ? `/${splitPathname[3]}` : ""
        }`
      );
    }
  }, []);

  // Open language dropdown
  let enableDropdownOpen: boolean = true;
  const handleLanguageDropdownClick = () => {
    if (!enableDropdownOpen) return;
    enableDropdownOpen = false;

    anime({
      targets: ".lang-item",
      opacity: 1,
      scale: [0.7, 1],
      duration: 400,
      delay: anime.stagger(50),
      easing: "easeInOutCirc",
    });
  };

  useClickOutside(navCurrentLanguageRef, () => {
    enableDropdownOpen = true;

    anime({
      targets: ".lang-item",
      opacity: 0,
      scale: 0.7,
      duration: 400,
      delay: anime.stagger(50, { direction: "reverse" }),
      easing: "easeInOutCirc",
    });
  });

  // Search
  const openSearch = () => {
    const navSearchEl: any = navSearchRef.current;
    const navSearchIconEL: any = navSearchIconRef.current;
    const navLogoEl = navLogoRef.current;
    const navMenuEl: any = navMenuRef.current;
    const navSearchInputEl = navSearchEl.children[0].children[1];

    navSearchInputEl.focus();

    let navActionGroup;
    if (width < 700) {
      navActionGroup = [navSearchIconEL, navLogoEl];
    } else {
      navActionGroup = [navSearchIconEL];
    }

    anime({
      targets: navActionGroup,
      opacity: 0,
      duration: 400,
      easing: "easeInOutCirc",
      begin: () => {
        navSearchIconEL.parentNode.style.zIndex = -1;
      },
    });

    anime({
      targets: navSearchEl,
      opacity: 1,
      duration: 400,
      easing: "easeInOutCirc",
    });

    anime({
      targets: navSearchEl.children[0],
      width: "100%",
      duration: 400,
      easing: "easeInOutCirc",
    });

    if (width >= 700) {
      anime({
        targets: navMenuEl,
        left: `-${navMenuEl.clientWidth - 48}px`,
        duration: 400,
        easing: "easeInOutCirc",
      });
    }
  };

  const closeSearch = () => {
    const navSearchEl: any = navSearchRef.current;
    const navSearchIconEL: any = navSearchIconRef.current;
    const navLogoEl = navLogoRef.current;
    const navMenuEl: any = navMenuRef.current;

    anime({
      targets: [navSearchIconEL, navLogoEl],
      opacity: 1,
      duration: 400,
      easing: "easeInOutCirc",
      begin: () => {
        navSearchIconEL.parentNode.style.zIndex = 2;
      },
    });

    anime({
      targets: navSearchEl,
      opacity: 0,
      duration: 400,
      easing: "easeInOutCirc",
    });

    anime({
      targets: navSearchEl.children[0],
      width: "0%",
      duration: 400,
      easing: "easeInOutCirc",
    });

    if (width >= 700) {
      anime({
        targets: navMenuEl,
        left: 0,
        duration: 400,
        easing: "easeInOutCirc",
      });
    }
  };

  const searchResult = (e: any) => {
    if (e.key === "Enter") {
      const value = e.target.value.toLowerCase();

      if (!value) return;
      window.location.href = `${process.env.PUBLIC_URL}${currentLanguage.slug}/wyniki-wyszukiwania/${value}`;
    }
  };

  // Menu
  const openMenu = () => {
    anime({
      targets: menuRef.current,
      opacity: 1,
      left: "0%",
      duration: 400,
      easing: "easeInOutCirc",
    });
  };

  const closeMenu = () => {
    anime({
      targets: menuRef.current,
      opacity: 0,
      left: "100%",
      duration: 400,
      easing: "easeInOutCirc",
    });
  };

  return (
    <>
      <Styles.Menu ref={menuRef}>
        <Grid>
          <Styles.MenuClose onClick={closeMenu} src={closeMenuIcon} />
          <Styles.MenuItems>
            {pages.map((item: any) => {
              if (!item.hidden)
                return (
                  <Styles.MenuItem
                    key={item.id}
                    href={`${process.env.PUBLIC_URL}/${item.slug}`}
                  >
                    <Breakpoint ms m>
                      <Typography type="subtitle">{item.label}</Typography>
                    </Breakpoint>

                    <Breakpoint tp>
                      <Typography type="header" variant="xs">
                        {item.label}
                      </Typography>
                    </Breakpoint>
                  </Styles.MenuItem>
                );

              return;
            })}
          </Styles.MenuItems>
          <Styles.MenuButtonGroup>
            {content.menu.menuButtonGroup.map((button) => (
              <Button
                width="fill"
                key={button.id}
                icon={button.icon}
                variant="tertiary"
              >
                <Typography type="button">{button.label}</Typography>
              </Button>
            ))}
          </Styles.MenuButtonGroup>
          <Styles.MenuLanguageItems>
            {content.languages.map((language) => (
              <Styles.MenuLanguageItem
                key={language.id}
                onClick={() => localStorage.setItem("language", language.code)}
                href={
                  `${language.slug}${currentPage}` === ""
                    ? "/"
                    : `${language.slug}${currentPage}`
                }
              >
                <Styles.MenuLanguageItemIcon src={language.icon} />
                <Typography type="caption">{language.label}</Typography>
              </Styles.MenuLanguageItem>
            ))}
          </Styles.MenuLanguageItems>
        </Grid>
      </Styles.Menu>

      <Styles.Nav>
        <Styles.NavLogo
          href={
            `${currentLanguage.slug}` === "" ? "/" : `${currentLanguage.slug}`
          }
        >
          <Styles.NavLogoIcon ref={navLogoRef} src={logoBlack} />
        </Styles.NavLogo>

        <Styles.NavSearchInputWrapper ref={navSearchRef}>
          <Styles.NavSearchInputContainer>
            <Styles.NavSearchInputIcon src={searchGrayIcon} />
            <Styles.NavSearchInput
              onKeyDown={searchResult}
              placeholder="Szukaj"
            />
            <Styles.NavSearchClose
              onClick={closeSearch}
              src={closeSearchIcon}
            />
          </Styles.NavSearchInputContainer>
        </Styles.NavSearchInputWrapper>

        <Styles.NavActionBar>
          <Styles.NavMenu ref={navMenuRef}>
            {pages.map((item: any) => (
              <Styles.NavMenuItem
                key={item.id}
                href={`${currentLanguage.slug}/${item.slug}`}
              >
                {item.label}
              </Styles.NavMenuItem>
            ))}
          </Styles.NavMenu>

          <Styles.NavSearchIcon
            ref={navSearchIconRef}
            onClick={openSearch}
            src={searchIcon}
          />
          <Styles.NavMenuIcon onClick={openMenu} src={menuIcon} />

          <Styles.NavLang>
            <Styles.NavLangSelected
              ref={navCurrentLanguageRef}
              onClick={handleLanguageDropdownClick}
            >
              <Styles.NavLangSelectedIcon src={currentLanguage.icon} />
              <Styles.NavLangSelectedLabel>
                <Typography type="caption">{currentLanguage.label}</Typography>
              </Styles.NavLangSelectedLabel>
              <Styles.NavLangExpand src={expandIcon} />
            </Styles.NavLangSelected>

            <Styles.NavLangDropdown ref={navLanguageDropdownRef}>
              {content.languages.map((language) => (
                <Styles.NavLangDropdownItem
                  key={language.id}
                  onClick={() =>
                    localStorage.setItem("language", language.code)
                  }
                  className="lang-item"
                  href={
                    `${language.slug}${currentPage}` === ""
                      ? "/"
                      : `${language.slug}${currentPage}`
                  }
                >
                  <Styles.NavLangDropdownItemIcon src={language.icon} />
                  <Styles.NavLangDropdownItemLabel>
                    <Typography type="caption">{language.label}</Typography>
                  </Styles.NavLangDropdownItemLabel>
                </Styles.NavLangDropdownItem>
              ))}
            </Styles.NavLangDropdown>
          </Styles.NavLang>
        </Styles.NavActionBar>
      </Styles.Nav>
    </>
  );
};

export { Nav };
