import { Breakpoint, Button, Grid, Typography, Image } from "design-system";
import * as Styles from "./Hero.style";

import crowsLineMobile from "assets/home/crowd-line-mobile.svg";
import crowsLineTp from "assets/home/crowd-line-tablet.svg";
import crowsLineDesktop from "assets/home/crowd-line-desktop.svg";

import suitsLineMobile from "assets/home/suits-line-mobile.svg";
import suitsLineTp from "assets/home/suits-line-tablet-portrait.svg";
import suitsLineDesktop from "assets/home/suits-line-desktop.svg";

const HeroSection = ({ content }: any) => {
  return (
    <Styles.HeroSection>
      <Grid>
        <Styles.HeroImageWrapper>
          <Breakpoint ms m>
            <Image
              className="hero-image"
              src={content.heroImage}
              aspectRatio={[4.5, 4]}
            />
            <Styles.HeroImageLine src={crowsLineMobile} />
          </Breakpoint>

          <Breakpoint tp tl>
            <Image
              className="hero-image"
              src={content.heroImage}
              aspectRatio={[4, 4.5]}
            />
          </Breakpoint>
        </Styles.HeroImageWrapper>

        <Breakpoint tp>
          <Styles.HeroImageLine src={crowsLineTp} />
        </Breakpoint>

        <Breakpoint tl>
          <Styles.HeroImageLine src={crowsLineDesktop} />
        </Breakpoint>

        <Styles.HeroTextContainer>
          <Styles.HeroHeader>
            <Breakpoint ms m>
              <Typography font="secondary" type="header" variant="l">
                {content.heroHeader}
              </Typography>
            </Breakpoint>

            <Breakpoint tp tl>
              <Typography font="secondary" type="header" variant="xxl">
                {content.heroHeader}
              </Typography>
            </Breakpoint>
          </Styles.HeroHeader>

          <Styles.HeroDescription>
            <Breakpoint ms m>
              <>{content.heroDesc1}</>
            </Breakpoint>

            <Breakpoint tp tl>
              <Typography type="header" variant="xs">
                {content.heroDesc1}
              </Typography>
            </Breakpoint>
          </Styles.HeroDescription>

          <Styles.HeroButton>
            <Button variant="black" href="#coop-section">
              <Typography type="button">{content.heroButton.label}</Typography>
            </Button>
          </Styles.HeroButton>
        </Styles.HeroTextContainer>

        <Styles.SecondDescription>
          <Breakpoint ms m>
            <Typography type="subtitle" variant="inline" strong>
              {content.heroDesc2}
            </Typography>
          </Breakpoint>

          <Breakpoint tp tl>
            <Typography type="header" variant="xs" strong>
              {content.heroDesc2}
            </Typography>
          </Breakpoint>
        </Styles.SecondDescription>

        <Image className="second-image" src={content.heroImage2} aspectRatio={[4, 3]} />

        <Styles.TertiaryDescription>
          <Breakpoint ms m>
            <div dangerouslySetInnerHTML={{ __html: content.heroDesc3 }} />
          </Breakpoint>

          <Breakpoint tp tl>
            <Typography type="header" variant="xs">
              {content.heroDesc3}
            </Typography>
          </Breakpoint>
        </Styles.TertiaryDescription>

        <Breakpoint ms m>
          <Styles.SecondLine src={suitsLineMobile} />
        </Breakpoint>

        <Breakpoint tp>
          <Styles.SecondLine src={suitsLineTp} />
        </Breakpoint>

        <Breakpoint tl>
          <Styles.SecondLine src={suitsLineDesktop} />
        </Breakpoint>
      </Grid>
    </Styles.HeroSection>
  );
};

export { HeroSection };
