import React from "react";
import * as Styles from "./Button.style";
import { buttons } from "../../variables";
var Button = function (props) {
    var tag = props.href ? "a" : "button";
    var type = props.type ? props.type : "filled";
    var variant = props.variant ? props.variant : "primary";
    var styles = buttons[type][variant];
    var iconPosition = props.iconPosition === "right" ? "1" : "0";
    return (React.createElement(Styles.Button, { onClick: props.onClick, as: tag, icon: !!props.icon, iconPosition: iconPosition, width: props.width, className: props.className, styles: styles, href: props.href },
        props.icon ? React.createElement(Styles.ButtonIcon, { src: props.icon }) : "",
        props.children));
};
export { Button };
