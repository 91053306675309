import React from "react";
import { TypographyStyle } from "./Typography.style";
import { BodyStyle } from "./Body.style";
import { AtomsStyle } from "./Atoms.style";
var CommonStyle = function () {
    return (React.createElement("div", null,
        React.createElement(BodyStyle, null),
        React.createElement(TypographyStyle, null),
        React.createElement(AtomsStyle, null)));
};
export { CommonStyle };
