import styled from "styled-components";
import { colors } from "design-system";

const IntroLogo = styled.img``;
const Intro = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  ${IntroLogo} {
    height: 32px;
  }
`;

export { Intro, IntroLogo };
