import { useEffect, useState } from "react";
import { useWindowSize } from "../../hooks";
var getBreakpoint = function () {
    var _a = useState("ms"), breakpoint = _a[0], setBreakpoint = _a[1];
    var width = useWindowSize().width;
    useEffect(function () {
        var breakpointString = window
            .getComputedStyle(document.body, ":before")
            .content.replace(/\"/g, "");
        setBreakpoint(breakpointString);
    }, [width]);
    return breakpoint;
};
export { getBreakpoint };
