import styled from "styled-components";
import { colors, devices, GridStyles } from "design-system";

// CTA section
const CTALine = styled.img``;
const CTADescription = styled.div``;
const CTAHeader = styled.div``;
const CTAButton = styled.div``;
const CTATextButtons = styled.div``;

const CTASection = styled.div`
  background-color: ${colors.secondary[100]};
  padding: 10vh 0;

  ${GridStyles.Grid} {
    grid-template-rows: auto auto 1fr auto auto;
  }

  ${CTALine} {
    grid-area: 1 / 1 / 4 / 1;
    padding-top: 6vh;
    margin-left: -16px;
    height: 40vh;
  }

  ${CTADescription} {
    grid-area: 1 / 1 / 2 / 5;
    padding: 0 24px 0 64px;
    color: ${colors.secondary[20]};
  }

  ${CTAHeader} {
    grid-area: 2 / 1 / 3 / 5;
    margin: 64px 0 32px 0;
    padding: 0 24px 0 64px;
  }

  ${CTAButton} {
    grid-area: 3 / 1 / 4 / 5;
    padding: 0 24px 0 64px;
  }

  ${CTATextButtons} {
    grid-area: 4 / 1 / 5 / 5;
    margin: 32px 0 32px 64px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 32px 0;
    * {
      width: fit-content;
      border-bottom: 1px solid ${colors.primary[80]};
      color: ${colors.primary[80]};
    }
  }

  ${devices.tabletPortrait} {
    ${CTALine} {
      grid-row-end: 5;
      padding-top: 0;
      padding-right: 16px;
      margin-left: -32px;
      height: unset;
    }

    ${CTADescription} {
      grid-column-start: 2;
      grid-column-end: 8;
      padding: 0 0 0 40px;
    }

    ${CTAHeader} {
      grid-column-start: 2;
      grid-column-end: 8;
      margin: 80px 0 40px 0;
      padding: 0 0 0 40px;
      height: fit-content;
    }

    ${CTAButton} {
      grid-column-start: 2;
      grid-column-end: 6;
      padding: 0 16px 0 40px;
    }

    ${CTATextButtons} {
      grid-column-start: 2;
      grid-column-end: 8;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;
      gap: 0 40px;
      margin-left: 40px;
      margin-bottom: 0;
    }
  }

  ${devices.tabletLandscape} {
    ${GridStyles.Grid} {
      grid-template-rows: auto auto auto 1fr;
    }

    ${CTALine} {
      padding-right: 0;
      grid-row-end: 6;
      margin-left: -20px;
      height: 52vh;
    }

    ${CTADescription} {
      margin-top: 6vh;
      grid-column-start: 3;
      grid-column-end: 10;
      padding: 0;
    }

    ${CTAHeader} {
      grid-column-start: 3;
      grid-column-end: 11;
      margin-top: 48px;
      margin-bottom: 64px;
      padding: 0;
    }

    ${CTAButton} {
      grid-column-start: 3;
      grid-column-end: 6;
      padding: 0 0 6vh 0;
    }

    ${CTATextButtons} {
      grid-area: 3 / 6 / 4 / 13;
      gap: 0 68px;
      padding-bottom: 6vh;
      margin: auto 0 auto 48px;
    }
  }
`;

export {
  CTASection,
  CTALine,
  CTADescription,
  CTAHeader,
  CTAButton,
  CTATextButtons,
};
