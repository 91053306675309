import styled from "styled-components";
import { colors, devices } from "design-system";

const CoopLine = styled.img``;
const CoopHeader = styled.div``;
const CoopBoxes = styled.div``;
const CoopBox = styled.div``;
const CoopBoxHeader = styled.div``;
const CoopBoxDescription = styled.div``;
const CoopBoxButtons = styled.div``;
const CoopTextButton = styled.div``;

const CoopSection = styled.section`
  ${CoopLine} {
    grid-area: 1 / 1 / 2 / 5;
    margin-left: -16px;
    width: 100vw;
    min-height: 26vh;
    object-fit: contain;
  }

  ${CoopHeader} {
    grid-area: 1 / 1 / 2 / 5;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 56px;
    margin-left: -16px;
  }

  ${CoopBoxes} {
    grid-area: 2 / 1 / 3 / 5;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px 0;
    margin-top: 24px;
    width: calc(100% - 16px);
  }

  ${CoopBox} {
    background: ${colors.white};
    box-shadow: 0px 8px 16px rgba(20, 110, 182, 0.26);
    border-radius: 12px;
    padding: 48px 32px;
    text-align: center;

    :first-child {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
    }

    :last-child {
      box-shadow: 0 8px 24px rgba(163, 25, 36, 0.26);
      ${CoopBoxHeader} {
        color: ${colors.destructive[40]};
      }

      ${CoopBoxButtons} {
        button,
        a {
          :first-child {
            background-color: ${colors.destructive[60]};
            border-color: ${colors.destructive[60]};
          }
        }
      }
    }

    ${CoopBoxDescription} {
      margin: 24px 0 52px 0;
    }

    ${CoopBoxButtons} {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      gap: 12px 0;
    }

    ${CoopTextButton} {
      cursor: pointer;
      border-bottom: 1px solid ${colors.primary[80]};
      color: ${colors.primary[80]};
      width: fit-content;
      margin: 24px auto 0 auto;
    }
  }

  ${devices.tabletPortrait} {
    ${CoopLine} {
      grid-column-end: 9;
      grid-row-end: 3;
      margin-left: -32px;
      min-height: 42vh;
      width: calc(100% + 64px);
    }

    ${CoopHeader} {
      grid-column-start: 3;
      grid-column-end: 7;
      padding: 0;
      margin-top: 104px;
    }

    ${CoopBoxes} {
      grid-column-start: 2;
      grid-column-end: 8;
      gap: 64px 0;
      margin-top: 48px;
      width: 100%;
    }

    ${CoopBox} {
      padding: 48px 32px;
      text-align: center;

      ${CoopBoxDescription} {
        margin: 24px 0 52px 0;
      }
      ${CoopBoxButtons} {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        gap: 12px 0;
      }

      ${CoopTextButton} {
        border-bottom: 1px solid ${colors.primary[80]};
        color: ${colors.primary[80]};
        width: fit-content;
        margin: 24px auto 0 auto;
      }
    }
  }

  ${devices.tabletLandscape} {
    ${CoopLine} {
      grid-column-end: 17;
      margin-left: -20px;
      width: calc(100% + 46px);
      height: unset;
    }

    ${CoopHeader} {
      grid-column-end: 15;
      margin-top: 16vh;
    }

    ${CoopBoxes} {
      grid-column-start: 3;
      grid-column-end: 15;
      grid-template-columns: repeat(3, 1fr);
      gap: 0 24px;
      padding-bottom: 14vh;
      height: fit-content;
    }

    ${CoopBox} {
      display: flex;
      flex-direction: column;
      padding: 64px 32px;

      ${CoopBoxDescription} {
        margin-bottom: 96px;
      }

      ${CoopBoxButtons} {
        margin-top: auto;
      }
    }
  }
`;

export {
  CoopSection,
  CoopLine,
  CoopHeader,
  CoopBoxes,
  CoopBox,
  CoopBoxHeader,
  CoopBoxDescription,
  CoopBoxButtons,
  CoopTextButton,
};
