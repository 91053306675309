import styled from "styled-components";
import { devices } from "design-system";

const ArticlesBoxes = styled.div``;
const ArticlesSection = styled.section`
  margin-bottom: 10vh;
  ${ArticlesBoxes} {
    grid-area: 1 / 1 / 2 / 5;
    padding: 0 8px;
    display: grid;
    gap: 32px 0;
  }

  ${devices.tabletPortrait} {
    ${ArticlesBoxes} {
      grid-area: 1 / 1 / 2 / 9;
      padding: 0;
      grid-template-columns: repeat(2, 1fr);
      gap: 48px 16px;
    }
  }

  ${devices.tabletLandscape} {
    ${ArticlesBoxes} {
      grid-column-start: 3;
      grid-column-end: 15;
      gap: 64px 24px;
    }
  }
`;

export { ArticlesSection, ArticlesBoxes };
