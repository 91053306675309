import styled from "styled-components";
import { colors, devices, GridStyles } from "design-system";

const NavLogo = styled.a``;
const NavLogoIcon = styled.img``;
const NavActionBar = styled.div``;
const NavMenuIcon = styled.img``;
const NavLang = styled.div``;
const NavLangSelected = styled.div``;
const NavLangSelectedIcon = styled.img``;
const NavLangSelectedLabel = styled.div``;
const NavLangExpand = styled.img``;
const NavLangDropdown = styled.div``;
const NavLangDropdownItem = styled.a``;
const NavLangDropdownItemIcon = styled.img``;
const NavLangDropdownItemLabel = styled.div``;
const NavSearchIcon = styled.img``;
const NavSearchClose = styled.img``;
const NavSearchInputWrapper = styled.div``;
const NavSearchInputContainer = styled.div``;
const NavSearchInput = styled.input``;
const NavSearchInputIcon = styled.img``;
const NavMenu = styled.div``;
const NavMenuItem = styled.a``;

const Nav = styled.nav`
  padding: 4vh 24px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;

  ${NavLogo} {
    z-index: 0;
  }

  ${NavLogoIcon} {
    cursor: pointer;
  }

  ${NavSearchInputWrapper} {
    width: calc(100% - 32px - 40px - 40px);
    right: calc(40px + 32px + 16px);
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${NavSearchInputContainer} {
      width: 0%;
      margin-left: auto;
      overflow: hidden;
      border: 1px solid ${colors.primary[100]};
      background-color: ${colors.white};
      border-radius: 4px;
      padding: 12px 16px;
      display: grid;
      gap: 0 12px;
      grid-template-columns: auto 1fr 16px;

      ${NavSearchInput} {
        font-family: "Montserrat", sans-serif;
        border: none;
        outline: none;
        ::placeholder {
          color: ${colors.primary[80]};
        }
      }

      ${NavSearchClose} {
        cursor: pointer;
      }
    }
  }

  ${NavActionBar} {
    z-index: 6;
    display: flex;

    ${NavMenu} {
      display: none;
    }

    ${NavLang} {
      display: none;
    }

    ${NavMenuIcon} {
      cursor: pointer;
      margin: auto 0 auto 32px;
    }

    ${NavSearchIcon} {
      cursor: pointer;
      margin: auto 0;
    }
  }

  ${devices.tabletPortrait} {
    padding: 4vh 40px;

    ${NavSearchInputWrapper} {
      width: unset;
      right: calc(40px + 32px + 32px);
    }

    ${NavLogoIcon} {
      height: 32px;
    }
  }

  ${devices.tabletLandscape} {
    padding: 32px 40px;

    ${NavSearchInputWrapper} {
      width: unset;
      right: calc(40px + 32px + 12px + 72px);
    }

    ${NavActionBar} {
      ${NavMenuIcon} {
        display: none;
      }

      ${NavLang} {
        display: block;
        position: relative;
        margin: auto 0 auto 56px;

        ${NavLangSelected} {
          cursor: pointer;
          display: flex;

          ${NavLangExpand} {
            margin: auto 0;
          }

          ${NavLangSelectedIcon} {
            margin: auto 0;
            width: 16px;
            height: 16px;
          }

          ${NavLangSelectedLabel} {
            margin: auto 8px;
            padding-top: 0.4px;
          }
        }

        ${NavLangDropdown} {
          margin-top: 20px;
          left: 0;
          position: absolute;

          ${NavLangDropdownItem} {
            cursor: pointer;
            opacity: 0;
            display: flex;
            margin-bottom: 24px;

            ${NavLangDropdownItemLabel} {
              margin: auto 0 auto 8px;
            }
          }
        }
      }

      ${NavMenu} {
        position: relative;
        margin: auto 56px auto 0;
        display: flex;

        ${NavMenuItem} {
          cursor: pointer;
          margin-left: 32px;
        }
      }
    }
  }
`;

const MenuClose = styled.img``;
const MenuItems = styled.div``;
const MenuItem = styled.a``;
const MenuButtonGroup = styled.div``;
const MenuLanguageItems = styled.div``;
const MenuLanguageItem = styled.a``;
const MenuLanguageItemIcon = styled.img``;

const Menu = styled.menu`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 100%;
  opacity: 0;
  background-color: ${colors.white};
  z-index: 100;
  padding: 4vh 0 10vh;

  ${GridStyles.GridContainer} {
    grid-template-rows: 40px auto 1fr 18px;
  }

  ${MenuClose} {
    grid-area: 1 / 1 / 2 / 5;
    margin-left: auto;
    margin-right: calc(4vh - 16px);
  }

  ${MenuItems} {
    cursor: pointer;
    height: fit-content;
    margin-top: 16vh;
    text-align: center;
    grid-area: 2 / 1 / 3 / 5;

    ${MenuItem} {
      display: block;
      margin-bottom: 32px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }

  ${MenuButtonGroup} {
    margin: 48px 0;
    grid-area: 3 / 1 / 4 / 5;
    padding: 0 36px;
    display: grid;
    gap: 8px 0;
  }

  ${MenuLanguageItems} {
    grid-area: 4 / 1 / 5 / 5;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 32px;
    ${MenuLanguageItem} {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  ${devices.tabletPortrait} {
    ${MenuClose} {
      grid-column-end: 9;
      margin-right: calc(4vh - 32px);
    }

    ${MenuItems} {
      grid-column-start: 3;
      grid-column-end: 7;
    }

    ${MenuButtonGroup} {
      grid-column-start: 3;
      grid-column-end: 7;
      padding: 0 16px;
      gap: 16px 0;
    }

    ${MenuLanguageItems} {
      grid-column-end: 9;
    }
  }
`;

export {
  Nav,
  NavLogo,
  NavLogoIcon,
  NavActionBar,
  NavMenuIcon,
  NavLang,
  NavLangSelected,
  NavLangSelectedIcon,
  NavLangSelectedLabel,
  NavLangExpand,
  NavLangDropdown,
  NavLangDropdownItem,
  NavLangDropdownItemIcon,
  NavLangDropdownItemLabel,
  NavSearchIcon,
  NavSearchClose,
  NavSearchInputWrapper,
  NavSearchInputContainer,
  NavSearchInput,
  NavSearchInputIcon,
  NavMenu,
  NavMenuItem,
  //
  Menu,
  MenuClose,
  MenuItems,
  MenuItem,
  MenuButtonGroup,
  MenuLanguageItems,
  MenuLanguageItem,
  MenuLanguageItemIcon,
};
