import { useEffect, useMemo, useRef, useState } from "react";
import { Grid, Typography } from "design-system";
import * as Styles from "./Pagination.style";
import arrowLeftIcon from "assets/common/arrow-left-black_icon.svg";
import arrowRightIcon from "assets/common/arrow-right-black_icon.svg";
import anime from "animejs";

const scrollToArticle = (articles: any) => {
  const top: number = articles.offsetTop;

  anime({
    targets:
      window.document.scrollingElement ||
      window.document.body ||
      window.document.documentElement,
    scrollTop: top - 164,
    duration: 600,
    easing: "easeInOutExpo",
  });
};

const PaginationSection = ({ articles, setPaginationArticles }: any) => {
  // State
  const [currentPage, setCurrentPage] = useState(1);

  // Refs
  const paginationSectionRef: any = useRef<HTMLDivElement>(null);

  // Pagination
  const POST_PER_PAGE = 2;
  const totalPages = Math.ceil(articles.length / POST_PER_PAGE);

  const currentPosts: any = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * POST_PER_PAGE;
    const lastPageIndex = firstPageIndex + POST_PER_PAGE;

    return articles.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, articles]);

  const paginationPrevPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
    scrollToArticle(paginationSectionRef.current.previousSibling);
  };
  const paginationNextPage = () => {
    if (currentPage === totalPages) return;
    setCurrentPage(currentPage + 1);
    scrollToArticle(paginationSectionRef.current.previousSibling);
  };

  useEffect(() => {
    if (currentPosts.length === 0) return setCurrentPage(1);
    setPaginationArticles(currentPosts);
  }, [currentPosts, articles]);

  // JSX content
  return (
    <Styles.PaginationSection ref={paginationSectionRef}>
      <Grid>
        <Styles.PaginationButtons>
          <Styles.PaginationButton
            onClick={paginationPrevPage}
            src={arrowLeftIcon}
          />
          <Styles.PaginationState>
            <Typography type="header" variant="s" font="secondary">
              {`Strona ${currentPage} z ${totalPages}`}
            </Typography>
          </Styles.PaginationState>
          <Styles.PaginationButton
            onClick={paginationNextPage}
            src={arrowRightIcon}
          />
        </Styles.PaginationButtons>
      </Grid>
    </Styles.PaginationSection>
  );
};

export { PaginationSection };
