import breakpoints from "./breakpoints.json";
var devices = {
    mobileSmall: "",
    mobile: "",
    tabletPortrait: "",
    tabletLandscape: "",
    desktopSmall: "",
    desktopMedium: "",
    desktopLarge: "",
    largeScreen: "",
};
breakpoints.map(function (breakpoint) {
    devices[breakpoint.breakpoint] = "@media only screen and (min-width: " + breakpoint.value + "px)";
});
export { devices };
