import { Typography, Breakpoint } from "design-system";
import * as Styles from "./ArticleBox.style";

import readMore from "assets/common/read-more.svg";

const ArticleBox = (props: {
  thumbnail: string;
  title: string;
  slug: string;
}) => {
  return (
    <Styles.ArticleBox href={props.slug}>
      <Styles.ArticleBoxImage src={props.thumbnail} />

      <Styles.ArticleBoxTitle>
        <Typography type="header" variant="s" font="secondary">
          {props.title}
        </Typography>
      </Styles.ArticleBoxTitle>

      <Styles.ArticleBoxReadButton>
        <Breakpoint ms m>
          <>Czytaj</>
        </Breakpoint>

        <Breakpoint tp tl>
          <Typography type="header" variant="xs" strong>
            Czytaj
          </Typography>
        </Breakpoint>
        <Styles.ArticleReadMoreIcon src={readMore} />
      </Styles.ArticleBoxReadButton>
    </Styles.ArticleBox>
  );
};

export { ArticleBox };
