import { useEffect, useState } from "react";
var getWindowSize = function () {
    var width = window.innerWidth, height = window.innerHeight;
    return {
        width: width,
        height: height,
    };
};
var useWindowSize = function () {
    var _a = useState(getWindowSize()), windowSize = _a[0], setWindowSize = _a[1];
    useEffect(function () {
        var handleResize = function () {
            setWindowSize(getWindowSize());
        };
        window.addEventListener("resize", handleResize);
        return function () { return window.removeEventListener("resize", handleResize); };
    }, []);
    return windowSize;
};
export { useWindowSize };
