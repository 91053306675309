import { Grid, Breakpoint, Typography } from "design-system";
import * as Styles from "./Client.style";

const ClientSection = ({ content }: any) => {
  return (
    <Styles.ClientSection>
      <Grid>
        <Styles.ClientHeader>
          <Breakpoint ms m>
            <Typography type="header" variant="s" font="secondary">
              {content.clientHeader}
            </Typography>
          </Breakpoint>

          <Breakpoint tp tl>
            <Typography type="header" variant="m" font="secondary">
              {content.clientHeader}
            </Typography>
          </Breakpoint>
        </Styles.ClientHeader>

        <Styles.ClientBoxes>
          {content.clientLogos.map((logo: { logo: string }, index: number) => (
            <Styles.ClientBox key={index} src={logo.logo} />
          ))}
        </Styles.ClientBoxes>
      </Grid>
    </Styles.ClientSection>
  );
};

export { ClientSection };
