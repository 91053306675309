import colors from "./colors.json";
var commonButtonsStyle = [
    {
        breakpoint: "mobileSmall",
        padding: "12px 16px",
        borderWidth: "1px",
        borderType: "solid",
        borderRadius: "4px",
    },
    {
        breakpoint: "tabletPortrait",
        padding: "12px 20px",
    },
    {
        breakpoint: "largeScreen",
        padding: "16px 24px",
    },
];
var buttons = {
    filled: {
        primary: {
            normal: {
                color: colors.black,
                bg: colors.primary[60],
                borderColor: colors.primary[60],
            },
            hover: {
                bg: colors.primary[40],
                borderColor: colors.primary[40],
            },
        },
        secondary: {
            normal: {
                color: colors.white,
                bg: colors.secondary[60],
                borderColor: colors.secondary[60],
            },
            hover: {
                bg: colors.secondary[40],
                borderColor: colors.secondary[40],
            },
        },
        tertiary: {
            normal: {
                color: colors.black,
                bg: "transparent",
                borderColor: colors.black,
            },
            hover: {
                bg: colors.gray[100],
                borderColor: colors.gray[100],
            },
        },
        black: {
            normal: {
                color: colors.white,
                bg: colors.black,
                borderColor: colors.black,
            },
        },
    },
    contrast: {
        primary: {
            normal: {
                color: colors.black,
                bg: colors.white,
                borderColor: colors.white,
            },
            hover: {
                bg: colors.gray[40],
                borderColor: colors.gray[40],
            },
        },
        secondary: {
            normal: {
                color: colors.white,
                bg: "transparent",
                borderColor: colors.white,
            },
            hover: {
                bg: colors.white + "42",
            },
        },
    },
};
export { buttons, commonButtonsStyle };
