import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Home, Search, Contact, Articles, SingleArticle } from "views";
import { Nav } from "components";

// Pages
const pages = [
  {
    id: 1,
    label: "Oferta",
    slug: "oferta",
    component: Home,
  },
  {
    id: 2,
    label: "Artykuły",
    slug: "artykuly",
    component: Home,
  },
  {
    id: 3,
    label: "Kontakt",
    slug: "kontakt",
    component: Home,
  },
];

function App() {
  const [loadingIntro, setIntroLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIntroLoading(false);
    }, 1200);
  }, []);

  return (
    <>
      <BrowserRouter basename="/">
        <Nav pages={pages} />

        <Switch>
          {/* Home */}
          <Route exact path="/">
            <Home language="" acfId={20} />
          </Route>

          <Route exact path="/en">
            <Home language="en" acfId={265} />
          </Route>

          <Route exact path="/uk">
            <Home language="uk" acfId={282} />
          </Route>

          {/* Search result */}
          <Route exact path="/wyniki-wyszukiwania/:phrase">
            <Search language="" acfId={229} />
          </Route>

          <Route exact path="/en/wyniki-wyszukiwania/:phrase">
            <Search language="en" acfId={267} />
          </Route>

          <Route exact path="/uk/wyniki-wyszukiwania/:phrase">
            <Search language="uk" acfId={273} />
          </Route>

          {/* Contact */}
          <Route exact path="/kontakt">
            <Contact language="" acfId={127} />
          </Route>

          <Route exact path="/en/kontakt">
            <Contact language="en" acfId={266} />
          </Route>

          <Route exact path="/uk/kontakt">
            <Contact language="uk" acfId={296} />
          </Route>

          {/* Articles */}
          <Route exact path="/artykuly" component={Articles} />

          {/* Single article */}
          <Route exact path="/artykuly/:slug" component={SingleArticle} />

          {/* 404 Error */}
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
