import styled from "styled-components";
import { colors, devices, GridStyles } from "design-system";

const FooterLogo = styled.img``;
const FooterLine = styled.img``;
const FooterCompanyDataGrid = styled.div``;
const FooterCompanyDataBox = styled.div``;
const FooterCompanyName = styled.div``;
const FooterBranding = styled.a``;

const Footer = styled.footer`
  background-color: ${colors.primary[60]};
  color: ${colors.white};
  padding: 84px 16px 40px 16px;
  position: relative;

  ${FooterLogo} {
    grid-area: 1 / 1 / 2 / 4;
    height: 48px;
  }

  ${FooterLine} {
    grid-area: 1 / 5 / 5 / 5;
    position: absolute;
    right: 0;
    top: 10vh;
    height: calc(100% - 10vh);
    object-fit: contain;
  }

  ${FooterCompanyDataGrid} {
    padding-right: 16px;
    grid-area: 2 / 1 / 3 / 5;
    margin-top: 48px;
    margin-bottom: 100px;
    display: grid;
    grid-template-rows: 1fr auto auto;
    gap: 24px 0;
    ${FooterCompanyDataBox} {
      display: grid;
      gap: 8px;
    }
  }

  ${FooterCompanyName} {
    grid-area: 3 / 1 / 4 / 5;
    color: ${colors.primary[80]};
    text-align: center;
  }

  ${FooterBranding} {
    grid-area: 4 / 1 / 5 / 5;
    margin: 8px auto 0 auto;
    transition: all 0.3s ease-in-out;

    :hover {
      filter: brightness(0) invert(1);
    }
  }

  ${devices.tabletPortrait} {
    padding: 112px 0 56px 0;

    ${FooterLogo} {
      height: 56px;
      margin-left: 16px;
    }

    ${FooterLine} {
      grid-area: 1 / 9 / 4 / 9;
      top: 6vh;
      height: calc(100% - 6vh);
    }

    ${FooterCompanyDataGrid} {
      padding-right: 40px;
      padding-left: 16px;
      grid-column-end: 8;
      margin: 92px 0;
      gap: 32px 0;
      grid-template-columns: repeat(2, 1fr);
      ${FooterCompanyDataBox} {
        :nth-child(2) {
          margin-left: auto;
        }
      }
    }

    ${FooterCompanyName} {
      grid-area: 3 / 1 / 4 / 5;
      text-align: left;
      padding-left: 16px;
    }

    ${FooterBranding} {
      height: 20px;
      margin: 0 40px 0 auto;
      grid-area: 3 / 5 / 4 / 8;
    }
  }

  ${devices.tabletLandscape} {
    padding: 128px 0 64px 0;

    ${GridStyles.GridContainer} {
      grid-template-rows: 56px auto 1fr;
    }

    ${FooterLogo} {
      grid-area: 1 / 3 / 2 / 8;
    }

    ${FooterLine} {
      grid-area: 1 / 14 / 4 / 17;
      top: 64px;
      height: calc(100% - 64px);
    }

    ${FooterCompanyDataGrid} {
      grid-area: 2/ 3 / 3 / 13;
      padding-right: 0;
      margin: 96px 0;
      gap: 0 40px;
      grid-template-columns: repeat(3, 1fr);
      height: fit-content;
      ${FooterCompanyDataBox} {
        height: fit-content;
        :nth-child(2) {
          margin-left: 0;
        }
        :nth-child(3) {
          margin-left: auto;
        }
      }
    }

    ${FooterCompanyName} {
      grid-area: 3 / 3 / 4 / 8;
      text-align: left;
      padding-left: 16px;
    }

    ${FooterBranding} {
      margin: 0 0 0 auto;
      grid-area: 3 / 8 / 4 / 13;
    }
  }
`;

export {
  Footer,
  FooterLogo,
  FooterLine,
  FooterCompanyDataGrid,
  FooterCompanyDataBox,
  FooterCompanyName,
  FooterBranding,
};
