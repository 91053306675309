import { useWindowSize } from "../../hooks";
import React, { useEffect, useRef, useState } from "react";
import * as Styles from "./Image.style";
var Image = function (props) {
    var _a;
    var _b = useState(0), height = _b[0], setHeight = _b[1];
    var imageContainer = useRef(null);
    var width = useWindowSize().width;
    // Get ratio width and height
    var _c = [1, 0], ratioWidth = _c[0], ratioHeight = _c[1];
    if (props.aspectRatio)
        _a = props.aspectRatio, ratioWidth = _a[0], ratioHeight = _a[1];
    // Calc proportional height
    useEffect(function () {
        setHeight((imageContainer.current.offsetWidth / ratioWidth) * ratioHeight);
    }, [width]);
    return (React.createElement(Styles.Image, { className: props.className, src: props.src, height: height, ref: imageContainer }));
};
export { Image };
