import { Grid, Typography, Breakpoint, Image } from "design-system";
import * as Styles from "./About.style";

import aboutLineMobile from "assets/home/lawyer-line-mobile.svg";
import aboutLineTp from "assets/home/lawyer-line-tablet-portrait.svg";
import aboutLineDesktopTop from "assets/home/lawyer-line-top-desktop.svg";
import aboutLineDesktopBottom from "assets/home/lawyer-line-bottom-desktop.svg";

const AboutSection = ({ content }: any) => {
  return (
    <Styles.AboutSection>
      <Grid>
        <Image
          className="about-image"
          src={content.aboutImage}
          aspectRatio={[3, 4]}
        />

        <Breakpoint ms m>
          <Styles.AboutLine src={aboutLineMobile} />
        </Breakpoint>

        <Breakpoint tp>
          <Styles.AboutLine src={aboutLineTp} />
        </Breakpoint>

        <Styles.AboutLineDummyPlug />
        <Styles.AboutDesktopTopLine src={aboutLineDesktopTop} />
        <Styles.AboutDesktopBottomLine src={aboutLineDesktopBottom} />

        <Styles.AboutHeader>
          <Breakpoint ms m tl>
            <Typography type="header" variant="xl" font="secondary">
              {content.aboutHeader}
            </Typography>
          </Breakpoint>

          <Breakpoint tp>
            <Typography type="header" variant="xxl" font="secondary">
              {content.aboutHeader}
            </Typography>
          </Breakpoint>
        </Styles.AboutHeader>

        <Styles.AboutSubtitle>
          <Typography type="button">{content.aboutName}</Typography>
        </Styles.AboutSubtitle>

        <Styles.AboutDescription>
          <>{content.aboutDesc}</>
        </Styles.AboutDescription>
      </Grid>
    </Styles.AboutSection>
  );
};

export { AboutSection };
