import * as Styles from "./Footer.style";
import { Grid, Typography, Breakpoint } from "design-system";

import logo from "assets/common/logo-white.svg";
import branding from "assets/common/branding.svg";

import footerMobileLine from "assets/footer/footer-line-mobile_vector.svg";
import footerTpLine from "assets/footer/footer-line-tablet-portrait_vector.svg";
import footerDesktopLine from "assets/footer/footer-line-desktop_vector.svg";

const Footer = () => {
  return (
    <Styles.Footer>
      <Grid>
        <Styles.FooterLogo src={logo} />

        <Breakpoint ms m>
          <Styles.FooterLine src={footerMobileLine} />
        </Breakpoint>

        <Breakpoint tp>
          <Styles.FooterLine src={footerTpLine} />
        </Breakpoint>

        <Breakpoint tl>
          <Styles.FooterLine src={footerDesktopLine} />
        </Breakpoint>

        <Styles.FooterCompanyDataGrid>
          <Styles.FooterCompanyDataBox>
            <Typography type="header" variant="xs" strong>
              Kancelaria Radcy Prawnego Maciej Trojanowski
            </Typography>
          </Styles.FooterCompanyDataBox>

          <Styles.FooterCompanyDataBox>
            <Typography type="subtitle">ul. Dworskiego 34/2</Typography>
            <Typography type="subtitle">37-700 Przemyśl</Typography>
          </Styles.FooterCompanyDataBox>

          <Styles.FooterCompanyDataBox>
            <Typography type="subtitle">+48 507 664 161</Typography>
            <Typography type="subtitle">
              kontakt@maciejtrojanowski.pl
            </Typography>
          </Styles.FooterCompanyDataBox>
        </Styles.FooterCompanyDataGrid>
        <Styles.FooterCompanyName>
          <Typography type="caption">© Trojanowski Radca Prawny</Typography>
        </Styles.FooterCompanyName>

        <Styles.FooterBranding
          target="_blank"
          href="https://s-sense.pl/?utm_source=maciejtrojanowski.com&utm_medium=refferal&utm_campaign=trojanowski_footer"
        >
          <img src={branding} />
        </Styles.FooterBranding>
      </Grid>
    </Styles.Footer>
  );
};

export { Footer };
