import { Button, Grid, Breakpoint, Typography } from "design-system";
import * as Styles from "./CTA.style";

import heroLineMobile from "assets/cta/cta-line-mobile_vector.svg";
import heroLineTabletPortrait from "assets/cta/cta-line-tablet-portrait_vector.svg";
import heroLineDesktop from "assets/cta/cta-line-desktop_vector.svg";

import commonContent from "../common.content.json";
const content = commonContent.components.cta;

const CTASection = () => {
  return (
    <Styles.CTASection>
      <Grid>
        <Breakpoint ms m>
          <Styles.CTALine src={heroLineMobile} />
        </Breakpoint>

        <Breakpoint tp>
          <Styles.CTALine src={heroLineTabletPortrait} />
        </Breakpoint>

        <Breakpoint tl>
          <Styles.CTALine src={heroLineDesktop} />
        </Breakpoint>

        <Styles.CTADescription>
          <Breakpoint ms m>
            <>{content.ctaDescription}</>
          </Breakpoint>

          <Breakpoint tp>
            <Typography type="subtitle">{content.ctaDescription}</Typography>
          </Breakpoint>

          <Breakpoint tl>
            <Typography type="header" variant="xs">
              {content.ctaDescription}
            </Typography>
          </Breakpoint>
        </Styles.CTADescription>

        <Styles.CTAHeader>
          <Breakpoint ms m>
            <Typography type="header" variant="s" font="secondary">
              {content.ctaHeader}
            </Typography>
          </Breakpoint>

          <Breakpoint tp>
            <Typography type="header" variant="l" font="secondary">
              {content.ctaHeader}
            </Typography>
          </Breakpoint>

          <Breakpoint tl>
            <Typography type="header" variant="xl" font="secondary">
              {content.ctaHeader}
            </Typography>
          </Breakpoint>
        </Styles.CTAHeader>

        <Styles.CTAButton>
          <Button width="fill" variant="black" href={content.ctaButton.url}>
            <Typography type="button">{content.ctaButton.label}</Typography>
          </Button>
        </Styles.CTAButton>

        <Styles.CTATextButtons>
          <Typography type="button">{content.ctaTextButton.label}</Typography>

          <Typography type="button">
            {content.ctaSecondTextButton.label}
          </Typography>
        </Styles.CTATextButtons>
      </Grid>
    </Styles.CTASection>
  );
};

export { CTASection };
