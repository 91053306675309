import { useEffect, useState } from "react";

// Sections
import { ArticlesSection, HeroSection, PaginationSection } from "./section";
import { CTASection, Footer } from "../../components";

// Content
import articlesContent from "./articles.content.json";

const content = articlesContent.section;

const Articles = () => {
  // State
  const [articles, setArticles] = useState(Array);
  const [paginationArticles, setPaginationArticles] = useState(Array);

  // Fetch articles
  useEffect(() => {
    setArticles(content.list.articles);
  }, []);

  // JSX content
  return (
    <>
      <HeroSection setArticles={setArticles} articles={articles} />
      <ArticlesSection
        paginationArticles={paginationArticles}
        articles={articles}
      />
      <PaginationSection
        articles={articles}
        setPaginationArticles={setPaginationArticles}
      />
      <CTASection />

      <Footer />
    </>
  );
};

export { Articles };
