var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { createGlobalStyle } from "styled-components";
import { typography, devices } from "../variables";
// Fluid
var fluidMinScope = typography.fluid.scope[0];
var fluidMaxScope = typography.fluid.scope[1];
var htmlLength = typography.html.length;
var fluidMinSize = parseInt(typography.html[0].size, 10);
var fluidMaxSize = parseInt(typography.html[htmlLength - 1].size, 10);
var TypographyStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  html {\n    ", ";\n\n    @media only screen and (min-width: ", "px ) and (max-width: ", "px ){\n      font-size: calc(\n        ", "px + (", " - ", ") *\n          (\n            (100vw - ", "px) /\n              (", " - ", ")\n          )\n      );\n    }\n  }\n\n  body {\n    line-height: 1.3;\n    font-family: ", ";\n    ", ";\n\n    ", "{\n      line-height: 1.5;\n    }\n  }\n"], ["\n  html {\n    ", ";\n\n    @media only screen and (min-width: ", "px ) and (max-width: ", "px ){\n      font-size: calc(\n        ", "px + (", " - ", ") *\n          (\n            (100vw - ", "px) /\n              (", " - ", ")\n          )\n      );\n    }\n  }\n\n  body {\n    line-height: 1.3;\n    font-family: ", ";\n    ", ";\n\n    ", "{\n      line-height: 1.5;\n    }\n  }\n"])), typography.html.map(function (current) {
    return devices[current.breakpoint] + "{\n          font-size: " + current.size + ";\n      }";
}), fluidMinScope, fluidMaxScope, fluidMinSize, fluidMaxSize, fluidMinSize, fluidMinScope, fluidMaxScope, fluidMinScope, typography.font.primary, typography.body.map(function (current) {
    return devices[current.breakpoint] + "{\n          font-size: " + (current.size ? current.size : "") + ";\n          letter-spacing: " + (current.spacing ? current.spacing : "") + ";\n          font-weight: " + (current.weight ? current.weight : "") + ";\n      }";
}), devices.largeScreen);
export { TypographyStyle };
var templateObject_1;
